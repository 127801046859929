/**
 * @description - The file contains the view and form in order to create a documentation.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
// React
import { useEffect, useMemo, useCallback, useState, useRef, MutableRefObject } from 'react';
// Parameters
import { useParams } from 'react-router-dom';
// Notification bar
import { useSnackbar } from "notistack";
import {styled} from "@mui/material/styles";
// Material Components
import { Dialog, Button, Box, Card, Stack, Grid, MenuItem, StepConnector, Stepper, Step, StepLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {getFirestore, setDoc, updateDoc, doc, Timestamp} from "firebase/firestore";
// PDF viewer
import { Viewer, LoadError, RenderPage, RenderPageProps, DocumentLoadEvent } from '@react-pdf-viewer/core';
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// Form
import { useForm } from "react-hook-form";
// Redux related
import { useDispatch, useSelector } from "src/redux/store";
// Hooks
import { useAuth, useLocalStorage } from "src/hooks";
// Local components
import { Iconify, FormProvider, RHFSelect, LoadingScreen, RHFUploadSingleFile } from 'src/components';
import PartiesContactForm from '../components/parties';
// Document types
import { TypeDocumentView, ModelNewContractForm } from 'src/@types';
// Slices/services
import { getPlatformProjects, createContractByAttachment } from "src/redux/slices";
// Services
import { fetchDocumentByUid, changeContractByUid, sendEmail, getByAssigned } from "src/services/documents/documents";
import SignatureDrawerDevice from "src/pages/docs/views/SignatureDrawerDevice";
import { generateCollectionReference } from "src/services/documents/queries";
import { collection, query, where, onSnapshot } from 'firebase/firestore';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { PATH_APP_HELP } from "src/routes";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import '@react-pdf-viewer/core/lib/styles/index.css';
import './custom.css';
import {getTemplateData, getTemplateRTData} from "src/services";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //
/**
 * @description - The method is a Document Creation View component that's need to create a documentation, contract
 * or act.
 * @constructor
 */
export default function DocEditView() {
  // ======================================== THE VIEW DATA INITIALIZATION ========================================== //
  // Notification bar
  const { enqueueSnackbar } = useSnackbar();
  // Dispatch
  const dispatch = useDispatch();
  // Get user and companies
  const { user } = useAuth();
  // Selected company
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', undefined);
  // The global state
  const { projects, isLoadingProjects, projectFetchingError } = useSelector((state) => state.platformProjects);
  const { isLoadingContracts, contractFetchingError, contract } = useSelector((state) => state.contracts);
  const [pdfFile, setPdfFile] = useState<any|null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { id } = useParams();
  const [signatureDialog, setSignatureDialog] = useState(false);
  const firestoreDb = getFirestore();

  // const pdfReference = useRef() as MutableRefObject<any>

  useEffect(() => {
    if (user && user.uid) {
      const documentsDbReference = generateCollectionReference('contract');
      const q = query(
        documentsDbReference,
        // Checking company based documents
        where('assigned_device', '==', user.uid),
        where('active', '==', true),
        where('visible', '==', true),
        where("statusProcessStage", "in", ["show", "sign", "confirm"])
      )
      // getByAssigned(user.uid, (data) => fetchDocumentByUid(data?.[0]?.uid, 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', setPdfFile, (error) => console.log(error)));

      // Setting up the listener
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
          // const docs: any = [];
          let needFetch = false;
          querySnapshot.forEach((doc) => {
            // docs.push({ ...doc.data(), id: doc.id });
            needFetch = true;
          });
          // setPdfFile(docs);
          if (needFetch) {
            getByAssigned(user.uid, (data) => fetchDocumentByUid(data?.[0]?.uid, 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', setPdfFile, (error) => console.log(error)));
          } else { setPdfFile(null) }
        },
        (error) => {
          // Handle the error
          console.error("Error getting documents: ", error);
        });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [user]);

  const renderError = (error: LoadError) => {
    
    return <div>
      {error.message}
    </div>
  }

  console.log(pdfFile);

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <>
      {props.canvasLayer.children}
      {/*<div*/}
      {/*  style={{*/}
      {/*    alignItems: 'center',*/}
      {/*    display: 'flex',*/}
      {/*    height: '100%',*/}
      {/*    // justifyContent: 'center',*/}
      {/*    position: 'absolute',*/}
      {/*    width: '100%',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    alt="..."*/}
      {/*    style={{*/}
      {/*      width: 80*/}
      {/*    }}*/}
      {/*    src={*/}
      {/*      // @ts-ignore*/}
      {/*      signatureBase*/}
      {/*    }*/}
      {/*  />*/}
      {/*</div>*/}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    '& .MuiStepConnector-line': {
      borderTopWidth: 2,
      borderColor: theme.palette.divider,
    },
    '&.Mui-active, &.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
    return (
      <Box
        sx={{
          zIndex: 9,
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: active ? 'primary.main' : 'text.disabled',
        }}
      >
        {completed ? (
          <Iconify
            icon={'eva:checkmark-fill'}
            sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
          />
        ) : (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'currentColor',
            }}
          />
        )}
      </Box>
    );
  }

  const steps = [
    {
      label: 'Նախագիծ և ներքին ստորագրություն',
      // email: 'a.arshakyan@uwcdilijan.am',
      // email: 'vladimirdanielyan@gmail.com',
      type: 'edit',
      index: 0,
      processing: false,
    },
    {
      label: 'Հաճախորդի Ստորագրություն',
      email: 'vladimirdanielyan@gmail.com',
      type: 'sign',
      index: 1,
      processing: false,
    }
  ]

  const currentStep = pdfFile?.step ?? 0;

  if ((pdfFile?.statusProcessStage === 'sign' || pdfFile?.statusProcessStage === 'confirm') && signatureDialog === false) {
    setSignatureDialog(true);
  }

  if (signatureDialog === true && pdfFile?.statusProcessStage !== 'sign' && pdfFile?.statusProcessStage !== 'confirm') {
    setSignatureDialog(false);
  }

  const signDocument = () => {
    // () => changeContractByUid(
    //   pdfFile.uid,
    //   { step: 1 },
    //   () => sendEmail(
    //     'vladimirdanielyan@gmail.com',
    //     'Your review/signature required for the contract' +
    //     ` https://rtarmenia.bisflow.io/app/active/contracts/${pdfFile?.uid}`,
    //     () => window.location.reload()
    //   ),
    //   (error) => console.error(error))
  }

  const [templateData, setTemplateData] = useState<Record<string, any> | null>(null);
  useEffect(() => {
    if (!templateData && pdfFile?.template_uid) {
      getTemplateRTData(pdfFile.template_uid, setTemplateData);
    }
  }, [pdfFile]);

  async function signPdfFile(signature: string, isInternal = true) {
    // setIsUploading(true);
    // const existingPdfBytes = await fetch(pdfFile?.attachment)
    //   .then((res) => res.arrayBuffer());
    //
    // const pdfDoc = await PDFDocument.load(existingPdfBytes);
    // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    //
    // const pages = pdfDoc.getPages();
    // const lastPage = pages[pages.length - 1];
    // const { width, height } = lastPage.getSize();
    // // lastPage.drawText("DEMO!!! Signed by Bisflow.io", {
    // //   x: 100,
    // //   y: height / 2 + 300,
    // //   size: 30,
    // //   font: helveticaFont,
    // //   color: rgb(0.95, 0.1, 0.1),
    // //   rotate: degrees(-45),
    // // });
    // const pngOrigImage = await pdfDoc.embedPng(signature);
    // const pngImage = pngOrigImage.scale(0.15);
    // if (templateData) {
    //   lastPage.drawImage(pngOrigImage, {
    //     x: width - templateData['2'].x - (pngImage.width / 2),
    //     y: templateData['2'].y - (pngImage.height / 2),
    //     width: pngImage.width,
    //     height: pngImage.height,
    //   });
    //
    //   const pdfBytes = await pdfDoc.save();
    //   // console.log(pdfBytes);
    //   const storage = getStorage();
    //   const storageRef = ref(storage, `pf0ArUxSiZNwqXZnun6rVZSkrRH2/contract/${pdfFile?.uid}/${pdfFile?.uid}_side_2.pdf`);
    //   uploadBytes(storageRef, pdfBytes).then((snapshot) => {
    //     setSignatureDialog(false);
        let signatureKey = pdfFile?.side2 === true ? 'signThird' : 'signFrom';
        changeContractByUid(
          pdfFile?.uid,
          { [signatureKey]: signature, processing: false, status: 'Done', updated: Timestamp.now(), step: pdfFile?.step ? pdfFile.step + 1 : 0, statusProcessStage: 'none' },
          () => {
            setPdfFile(null); // No automated email
            setIsUploading(false); // No automated email
            // fetchDocumentByUid(id ?? pdfFile?.uid, 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', (pdfFileModified) => {
            //   setPdfFile(null); // No automated email
            //   setIsUploading(false); // No automated email
            //   // sendEmail(
            //   //   pdfFile?.to?.email ?? 'vlad@bisflow.io',
            //   //   `<!DOCTYPE html><html><head><title>Email with Image and PDF Link</title></head><body><img src="https://firebasestorage.googleapis.com/v0/b/bisflow.appspot.com/o/brands%2Frtarmenia%2F%D5%86%D5%A1%D5%B4%D5%A1%D5%AF%D5%AB%20%D5%A2%D5%B8%D5%BE%D5%A1%D5%B6%D5%A4%D5%A1%D5%AF%D5%B8%D6%82%D5%A9%D5%B5%D5%B8%D6%82%D5%B6.1.jpeg?alt=media&token=6065f85f-d78d-4084-98c7-e61c1733c89c" alt="Letter Content Image" style="width:100%; max-width:600px; height:auto;"></body></html>`,
            //   //   () => {
            //   //     // enqueueSnackbar('Signed', { variant: 'success' });
            //   //     setPdfFile(null);
            //   //     setIsUploading(false);
            //   //   },
            //   //   [{
            //   //     filename: 'RTarmenia_contract.pdf',
            //   //     path: pdfFileModified?.attachment
            //   //   }]
            //   // )
            // }, (error) => {console.log(error); setIsUploading(false); });
            // setIsUploading(false);
          },
          (error) => console.error(error)
        )
      // });
    // }
  }
  // <!DOCTYPE html>
  // <html>
  // <head>
  //     <title>Email with Image</title>
  // </head>
  // <body>
  //     <img src="https://firebasestorage.googleapis.com/v0/b/bisflow.appspot.com/o/brands%2Frtarmenia%2F%D5%86%D5%A1%D5%B4%D5%A1%D5%AF%D5%AB%20%D5%A2%D5%B8%D5%BE%D5%A1%D5%B6%D5%A4%D5%A1%D5%AF%D5%B8%D6%82%D5%A9%D5%B5%D5%B8%D6%82%D5%B6.1.jpeg?alt=media&token=6065f85f-d78d-4084-98c7-e61c1733c89c" alt="Letter Content Image" style="width:100%; max-width:600px; height:auto;">
  // </body>
  // </html>

  const handleDocumentLoad = (event: DocumentLoadEvent) => {
    // Apply fullscreen style to the first page when the document loads
    const firstPage = document.querySelector(".rpv-core__page-layer");
    if (firstPage) {
      firstPage.classList.add("fullscreen-pdf-page");
    }
  };

  if (isUploading) {
    return <LoadingScreen />
  }

  /**
   * @description - The document creation view
   */
  if (pdfFile) {
    return (
      <Grid container spacing={3}>
        {/*{pdfFile?.processing !== true && typeof pdfFile?.attachment === 'string' && (*/}
        {/*  <Viewer*/}
        {/*    fileUrl={pdfFile.attachment}*/}
        {/*    renderError={renderError}*/}
        {/*    key={Math.random()}*/}
        {/*    onDocumentLoad={handleDocumentLoad}*/}
        {/*  />)*/}
        {/*}*/}
        <Dialog
          open={signatureDialog}
          onClose={() => {}}
          // fullWidth
          maxWidth="xs"
        >
          <SignatureDrawerDevice
            status={pdfFile?.statusProcessStage}
            onDialogClick={(base64Image: any) => {
              // signPdfFile(base64Image).then(() => fetchDocumentByUid(id ?? '', 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', setPdfFile, (error) => console.log(error)));
              signPdfFile(base64Image).then(() => {
                //   ToDo
                changeContractByUid(
                  pdfFile?.uid,
                  { status: 'Done', updated: Timestamp.now(), statusProcessStage: 'none' },
                  () => {console.log('success')},
                  // () => sendEmail(
                  //   pdfFile?.to?.email ?? 'vlad@bisflow.io',
                  //   `Your contract is available at bisflow.io`,
                  //   () => {
                  //     enqueueSnackbar('Signed', { variant: 'success' });
                  //     fetchDocumentByUid(id ?? '', 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', setPdfFile, (error) => console.log(error));
                  //     setIsUploading(false);
                  //   }
                  // ),
                  (error) => console.error(error)
                )
              });
              // changeContractByUid(
              //   pdfFile?.uid,
              //   { signFrom: base64Image, processing: true, status: 'Processing', updated: Timestamp.now() },
              //   () => sendEmail(
              //     'vladimirdanielyan@gmail.com',
              //     `signed ${pdfFile.uid}`,
              //     () => window.location.reload()
              //   ),
              //   (error) => console.error(error)
              // )
              // if (base64Image) {
              //   updateDoc(doc(firestoreDb, 'documentsDemo', selectedDocumentId), {
              //     [user?.uid ?? 'none']: base64Image,
              //     sign1: base64Image,
              //     status: `Signed by ${user?.email ?? ''}`
              //   })
              //     .then(() => setSignatureDialog(false))
              //     .catch((error) => {
              //       console.error(error);
              //       setSignatureDialog(false);
              //     })
              // }
            }}
            onSaveTemplateClick={(base64Image: string) => {
              if (base64Image) {
                updateDoc(doc(firestoreDb, 'users', user?.uid ?? 'na'), {
                  signature: base64Image
                }).then(() => enqueueSnackbar('Your signature updated', { variant: 'success' }))
                  .catch((error: Error) => enqueueSnackbar('Something went wrong', { variant: 'error' }))
              }
            }}
            onUseTemplate={() => {
              if (user?.signature) {
                signPdfFile(user.signature).then(() => fetchDocumentByUid(id ?? '', 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', setPdfFile, (error) => console.log(error)))
              } else {
                enqueueSnackbar('No signature found', { variant: 'error' })
              }
            }}
            onCancelDialog={() => setSignatureDialog(false)}
          />
        </Dialog>
        {/*{pdfFile?.attachment && (<Grid item xs={12} md={12}>*/}
        {/*  <Button href={pdfFile.attachment} target="_blank" rel="noopener" variant="contained">*/}
        {/*    Բեռնել*/}
        {/*  </Button>*/}
        {/*</Grid>)}*/}
        {/*{pdfFile?.processing === true && (<Grid item md={12}>*/}
        {/*  <h2 style={{ textAlign: 'center' }}>The signature is in process please wait, this may take up to 30sec. Please*/}
        {/*    refresh later</h2>*/}
        {/*</Grid>)}*/}
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            {/*<Stepper*/}
            {/*  alternativeLabel*/}
            {/*  activeStep={currentStep}*/}
            {/*  connector={<QontoConnector/>}*/}
            {/*  sx={{mt: 2, mb: 2}}*/}
            {/*>*/}
            {/*  {steps.map((label) => (*/}
            {/*    <Step key={label.email}>*/}
            {/*      <StepLabel*/}
            {/*        StepIconComponent={QontoStepIcon}*/}
            {/*        sx={{*/}
            {/*          '& .MuiStepLabel-label': {*/}
            {/*            typography: 'subtitle2',*/}
            {/*            color: 'text.disabled'*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {label.label}*/}
            {/*      </StepLabel>*/}
            {/*    </Step>*/}
            {/*  ))}*/}
            {/*</Stepper>*/}

            {/*<Stack spacing={3}>*/}
            {/*  <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>*/}
            {/*    <div className="PDF-viewer">*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          height: '750px',*/}
            {/*          padding: '18px'*/}
            {/*        }}*/}
            {/*      >*/}
            {pdfFile?.processing !== true && typeof pdfFile?.attachment === 'string' && (
              <Viewer
                fileUrl={pdfFile.attachment}
                renderError={renderError}
                key={Math.random()}
                onDocumentLoad={handleDocumentLoad}
              />)
            }
            {/*  </div>*/}
            {/*</div>*/}
            {/*</Stack>*/}
            {/*</Stack>*/}
            {currentStep === 0 && pdfFile?.uid && (
              //   <Button
              //     fullWidth
              //     variant="contained"
              //     sx={{marginTop: 2}}
              //     onClick={() => changeContractByUid(
              //       pdfFile.uid,
              //       { step: 1, status: 'Internal Signature', updated: Timestamp.now() },
              //       () => sendEmail(
              //         pdfFile?.from?.email ?? 'vladimirdanielyan@gmail.com',
              //         'Your review/signature required for the contract' +
              //         ` https://rostelecom.armenia.bisflow.io/app/active/contracts/${pdfFile?.uid}`,
              //         () => window.location.reload()
              //       ),
              //       (error) => console.error(error))
              //   }
              //   >
              //     Send for internal signature
              //   </Button>
              // )}
              // {currentStep === 1 && pdfFile?.uid && pdfFile?.from?.user_uid === user?.uid && pdfFile?.signFrom !== 'string' && (
              // <Button
              //   fullWidth
              //   variant="contained"
              //   sx={{ marginTop: 2 }}
              //   onClick={() => setSignatureDialog(true)}
              // >
              //   Ստորագրել | Sign
              // </Button>
              <></>
            )}
            {/*{currentStep === 1 && pdfFile?.uid && pdfFile?.to?.user_uid === user?.uid && pdfFile?.signTo !== 'string' && (*/}
            {/*  <Button*/}
            {/*    fullWidth*/}
            {/*    variant="contained"*/}
            {/*    sx={{marginTop: 2}}*/}
            {/*    onClick={() => setSignatureDialog(true)}*/}
            {/*  >*/}
            {/*    Sign*/}
            {/*  </Button>*/}
            {/*)}*/}
            {/*{currentStep === 1 && typeof pdfFile?.signFrom === 'string' && pdfFile?.processing !== true && (*/}
            {/*  <Button*/}
            {/*    fullWidth*/}
            {/*    variant="contained"*/}
            {/*    sx={{marginTop: 2}}*/}
            {/*    onClick={() => changeContractByUid(*/}
            {/*      pdfFile.uid,*/}
            {/*      { step: 2, status: 'External Signature', updated: Timestamp.now() },*/}
            {/*      () => sendEmail(*/}
            {/*        'vladimirdanielyan@gmail.com',*/}
            {/*        'Your review/signature required for the contract' +*/}
            {/*        ` https://rostelecom.armenia.bisflow.io/app/active/contracts/${pdfFile?.uid}`,*/}
            {/*        () => window.location.reload()*/}
            {/*      ),*/}
            {/*      (error) => console.error(error))*/}
            {/*    }*/}
            {/*  >*/}
            {/*    Send for external signature*/}
            {/*  </Button>*/}
            {/*)}*/}
          </Card>
        </Grid>
      </Grid>
    )
  }

  return <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <span style={{
      color: '#fff',
      fontSize: '10rem',
      fontWeight: 'bold',
    }}>
      {user?.display_name ?? '1'}
    </span>
  </div>
}
