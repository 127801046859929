/**
 * @description - The signature drawer view component. Allowing to draw a
 * signature
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { MutableRefObject, useEffect, useRef } from 'react';
// Material UI's
import { Button } from '@mui/material';
// The signature canvas external library
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas-react17-compatible';
import { useLocales } from "src/hooks";

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

interface SignatureDrawerProps {
  onDialogClick: (base64Image: any) => void;
  onSaveTemplateClick: (base64Image: any) => void;
  onUseTemplate: () => void;
  onCancelDialog: () => void;
  status?: string;
  onlyTemplate?: boolean;
}

export default function SignatureDrawer({
                                          onDialogClick,
                                          onSaveTemplateClick,
                                          onUseTemplate,
                                          onCancelDialog,
                                          status,
                                          onlyTemplate
                                        }: SignatureDrawerProps) {
  const { translate } = useLocales();
  const getWidth = () => {
    return window.innerWidth;
  };
  //
  // useEffect(() => {
  //   if (status === 'confirm') {
  //     const currentSignature = padRef.current
  //       .getTrimmedCanvas()
  //       .toDataURL('image/png');
  //     onDialogClick(currentSignature);
  //   }
  // }, [status]);

  const getHeight = () => {
    return window.innerHeight - 50;
  };

  let padRef = useRef() as MutableRefObject<any>;
  const clear = () => {
    if (padRef) {
      padRef.current.clear();
    }
  };
  const save = () => {
    const currentSignature = padRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    console.log(currentSignature);
    onDialogClick(currentSignature);
  };

  const saveSignature = () => {
    const currentSignature = padRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');
    onSaveTemplateClick(currentSignature);
  }
  const onCancel = () => {
    onCancelDialog();
  };
  return (
    <div style={{ width: getWidth(), height: getHeight() }}>
      {!onlyTemplate && (<Button sx={{ margin: 2 }} onClick={save}>
        {translate('sign')}
      </Button>)}
      {!onlyTemplate && (<Button sx={{ margin: 2 }} onClick={onUseTemplate}>
        {translate('signByTemplate')}
      </Button>)}
      {!onlyTemplate && (<Button sx={{ margin: 2 }} onClick={onCancel} color={'error'}>
        {translate('cancel')}
      </Button>)}
      <Button sx={{ margin: 2 }} onClick={clear} color={'inherit'}>
        {translate('erase')}
      </Button>
      <Button sx={{ margin: 2 }} onClick={saveSignature} color={'inherit'}>
        {translate('saveAsTemplate')}
      </Button>
      <SignatureCanvas
        penColor="blue"
        canvasProps={{
          width: getWidth(),
          height: getHeight(),
          className: 'sigCanvas'
        }}
        ref={padRef}
      />
    </div>
  );
}
