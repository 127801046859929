/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from '../store';
// Local types
import { TypePlatforms, ModelProject } from 'src/@types';
// API services
import { fetchAllProjectsByPlatform } from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type ProjectState = {
  isLoadingProjects: boolean;
  projectFetchingError: Error | string | null;
  projects: ModelProject[];
};

/**
 * @description - The initialization of the new request form state
 */
const newRequestFormInitialState: ProjectState = {
  isLoadingProjects: false,
  projectFetchingError: null,
  projects: [],
}

/**
 * @description - The slices for the new request form
 */
const platformProjectsSlice = createSlice({
  name: 'platformProjects',
  initialState: newRequestFormInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingProjects = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoadingProjects = false;
      state.projectFetchingError = action.payload;
      state.projects = [];
    },
    // GET PLATFORM's PROJECTS
    getPlatformProjectsSuccess(state, action) {
      state.isLoadingProjects = false;
      state.projects = action.payload;
      state.projectFetchingError = null;
    },
  }
});
// The reducers
export default platformProjectsSlice.reducer;

// State base actions
export const {} = platformProjectsSlice.actions;

/**
 * @description - The method is fetching new request forms from the server
 */
export function getPlatformProjects(userUid?: string, companyUid?: string) {
  return () => {
    dispatch(platformProjectsSlice.actions.startLoading());
    try {
      fetchAllProjectsByPlatform(
        (process.env.REACT_APP_NAME ?? 'BISFLOW') as TypePlatforms,
        (dataList) =>
          dispatch(platformProjectsSlice.actions.getPlatformProjectsSuccess(dataList)),
        (error) => dispatch(platformProjectsSlice.actions.hasError(error)),
        companyUid,
        userUid,
      );
    } catch (error) {
      dispatch(platformProjectsSlice.actions.hasError(error));
    }
  }
}
