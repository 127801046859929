import { ReactNode } from 'react';
import { useAuth, useLocalStorage } from '../../hooks';
import PagePermissionDenied from '../../pages/access/PagePermissionDenied';
import { LoadingScreen } from '../pages';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
  path: string;
  isAdmin: boolean;
};

const checkAdminPermission = (selectedCompany: any, adminList?: string[], companiesList?: any[]) => {
  return adminList?.includes(selectedCompany?.uid) || adminList?.includes(companiesList?.[0]?.uid);
}

export default function RoleBasedGuard({ accessibleRoles, children, isAdmin, path }: RoleBasedGuardProp) {
  // Getting user based data
  const { companies, admin, permissions } = useAuth();
  // getting the company profile;
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', {});
  if (!admin || !companies) {
    return (<LoadingScreen />);
  }

  // Checking if the admin view defined and user does not have proper administrative permission
  if (isAdmin && !checkAdminPermission(selectedCompany, admin, companies)) {
    return (<PagePermissionDenied path={path} />);
  }

  return <>{children}</>;
}
