/**
 * @description - The dialog view to pick contacts
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //


// @mui
import { Dialog, ListItemButton, Stack, Typography, Button } from '@mui/material';
// components
import { Iconify, Scrollbar } from 'src/components';
// Types
import { ModelContact } from 'src/@types';
import { useLocales } from "src/hooks";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The props for the dialog
 */
type Props = {
  open: boolean;
  selected: (selectedId: string) => boolean;
  onClose: VoidFunction;
  onSelect: (contact: ModelContact) => void;
  contactsOptions: ModelContact[] | null;
};

/**
 * @description - The method is a dialog view for the document contact selection
 * @param open
 * @param selected
 * @param onClose
 * @param onSelect
 * @param contactsOptions
 * @constructor
 */
export default function DocumentContactListDialog({
  open,
  selected,
  onClose,
  onSelect,
  contactsOptions,
}: Props) {
  const { translate } = useLocales();
  const handleSelect = (contact: ModelContact) => {
    onSelect(contact);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> {translate('selectDevice')} </Typography>

        {!contactsOptions && (<Button
          size="small"
          variant="outlined"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ alignSelf: 'flex-end' }}
        >
          Add New
        </Button>)}
      </Stack>

      <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
        {contactsOptions?.map((contact) => (
          <ListItemButton
            key={contact.uid}
            selected={selected(contact.uid)}
            onClick={() => handleSelect(contact)}
            sx={{
              p: 1.5,
              borderRadius: 1,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="subtitle2">{contact.display_name}</Typography>
            {/*<Typography*/}
            {/*  variant="caption"*/}
            {/*  sx={{ color: 'primary.main', my: 0.5, fontWeight: 'fontWeightMedium' }}*/}
            {/*>*/}
            {/*  {contact.company_uid}*/}
            {/*</Typography>*/}
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {contact.email}
            </Typography>
          </ListItemButton>
        ))}
      </Scrollbar>
    </Dialog>
  );
}
