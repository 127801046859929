import { PATH_APP_ADMIN } from './index';

// Icons
import {
  icon_elements,
  icon_forms,
  icon_user,
  icon_budgets,
  icon_payments,
  icon_invoices,
  icon_box,
  icon_stock,
  icon_requests,
  icon_authorizations,
  icon_documents,
  icon_archive,
  icon_charts,
  icon_reports2,
  icon_analytics,
  icon_store,
  icon_team,
  icon_department,
  icon_process,
  icon_procurement,
} from '../components';

export default [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_APP_ADMIN.dashboardFull,
        icon: icon_elements
      }
    ]
  },
  {
    subheader: 'processes',
    items: [
      {
        title: 'forms',
        path: PATH_APP_ADMIN.processes.formsFull,
        icon: icon_forms
      },
      {
        title: 'flows',
        path: PATH_APP_ADMIN.processes.flowsFull,
        icon: icon_process
      }
    ]
  },
  {
    subheader: 'personnel',
    items: [
      {
        title: 'departments',
        path: PATH_APP_ADMIN.personnel.departmentsFull,
        icon: icon_department
      },
      {
        title: 'groups',
        path: PATH_APP_ADMIN.personnel.groupsFull,
        icon: icon_team
      },
      {
        title: 'team',
        path: PATH_APP_ADMIN.personnel.teamFull,
        icon: icon_user
      }
    ]
  },
  {
    subheader: 'finance',
    items: [
      {
        title: 'budgets',
        path: PATH_APP_ADMIN.finance.budgetsFull,
        icon: icon_budgets
      },
      {
        title: 'payments',
        path: PATH_APP_ADMIN.finance.paymentsFull,
        icon: icon_payments
      },
      {
        title: 'invoices',
        path: PATH_APP_ADMIN.finance.invoicesFull,
        icon: icon_invoices
      }
    ]
  },
  {
    subheader: 'items',
    items: [
      {
        title: 'stocks',
        path: PATH_APP_ADMIN.inventory.stocksFull,
        icon: icon_stock
      },
      {
        title: 'goods',
        path: PATH_APP_ADMIN.inventory.goodsFull,
        icon: icon_box
      },
    ]
  },
  {
    subheader: 'providers',
    items: [
      {
        title: 'vendors',
        path: PATH_APP_ADMIN.vendors.manageFull,
        icon: icon_store,
      }
    ]
  },
  {
    subheader: 'requests',
    items: [
      {
        title: 'goods requests',
        path: PATH_APP_ADMIN.requests.goodsFull,
        icon: icon_requests,
      }
    ]
  },
  {
    subheader: 'documents',
    items: [
      {
        title: 'authorizations',
        path: PATH_APP_ADMIN.documents.authorizationsFull,
        icon: icon_authorizations
      },
      {
        title: 'active',
        path: PATH_APP_ADMIN.documents.activeFull,
        icon: icon_documents
      },
      {
        title: 'archive',
        path: PATH_APP_ADMIN.documents.archiveFull,
        icon: icon_archive
      }
    ]
  },
  {
    subheader: 'reports',
    items: [
      {
        title: 'procurement',
        path: PATH_APP_ADMIN.reports.procurementFull,
        icon: icon_procurement
      },
      {
        title: 'documents',
        path: PATH_APP_ADMIN.reports.documentsFull,
        icon: icon_reports2
      },
      {
        title: 'financial',
        path: PATH_APP_ADMIN.reports.financialFull,
        icon: icon_analytics
      }
    ]
  },
  {
    subheader: 'analytic',
    items: [
      {
        title: 'procurement',
        path: PATH_APP_ADMIN.analytics.procurementFull,
        icon: icon_procurement
      },
      {
        title: 'documents',
        path: PATH_APP_ADMIN.analytics.documentsFull,
        icon: icon_reports2
      },
      {
        title: 'financial',
        path: PATH_APP_ADMIN.analytics.financialFull,
        icon: icon_analytics
      }
    ]
  }
]