/**
 * @description - The loadable wrapper component that is detecting if the user is logged in and the page type is
 * dashboard layout. In that case the view will show the subcomponent.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { Suspense, lazy, ElementType } from 'react';
// Routing and navigation
import { useLocation } from 'react-router-dom';
// Paths - The full path to the file is necessary
import { PATH_APP_PLATFORM } from '../../routes/paths';
// Local components
import { LoadingScreen } from './index';
// Hooks
import { useAuth } from '../../hooks';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The loadable wrapper component that is detecting if the user is logged in and the page type is
 * dashboard layout. In that case the view will show the subcomponent
 * @param Component
 * @constructor
 */
const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname?.includes(PATH_APP_PLATFORM.rootFull) && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
