/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Layouts
import { LogoOnlyLayout } from 'src/layouts';
// Paths
import { PATH_APP_AUTH, PATH_APP_REQUESTS } from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Application selection Pages
const CreateRequestPage = Loadable(lazy(() => import('../pages/requests/CreateRequestPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Request routes list, contains list of RouteItem type objects.
 */
const requestsRoutes: TypeRouteItem = {
  path: PATH_APP_REQUESTS.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <LogoOnlyLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_REQUESTS.createFull} replace />, index: true },
    // Applications
    {
      path: PATH_APP_REQUESTS.create,
      element: <CreateRequestPage />
    }
  ]
};

export default requestsRoutes;
