/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from '../store';
// Local types
import { TypeFormNewRequest } from '../../@types';
// API services
import { fetchNewRequestForms } from '../../services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type NewRequestFormState = {
  isLoading: boolean;
  error: Error | string | null;
  requestForms: TypeFormNewRequest[];
  requestForm: TypeFormNewRequest | null;
};
/**
 * @description - The initialization of the new request form state
 */
const newRequestFormInitialState: NewRequestFormState = {
  isLoading: false,
  error: null,
  requestForms: [],
  requestForm: null,
}

/**
 * @description - The slices for the new request form
 */
const newRequestFormSlice = createSlice({
  name: 'create-request',
  initialState: newRequestFormInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET REQUEST FORM
    getNewRequestsFormsSuccess(state, action) {
      state.isLoading = false;
      state.requestForms = action.payload;
    },

    // GET REQUEST FORM
    getNewRequestFormSuccess(state, action) {
      state.isLoading = false;
      state.requestForm = action.payload;
    },
  }
});

export default newRequestFormSlice.reducer;

// State base actions
export const {} = newRequestFormSlice.actions;

/**
 * @description - The method is fetching new request forms from the server
 */
export function getNewRequestForms(userUid?: string, companies?: string[]) {
  return () => {
    dispatch(newRequestFormSlice.actions.startLoading());
    try {
      fetchNewRequestForms(
        (dataList) =>
          dispatch(newRequestFormSlice.actions.getNewRequestsFormsSuccess(dataList)),
        (error) => dispatch(newRequestFormSlice.actions.hasError(error)),
        userUid,
        companies,
      );
    } catch (error) {
      dispatch(newRequestFormSlice.actions.hasError(error));
    }
  }
}

// /**
//  * @description - The method is getting the new request form by the uid
//  * @param uid
//  */
// export function getNewRequestForm(uid: string) {
//   return () => {
//     dispatch(newRequestFormSlice.actions.startLoading());
//     try {
//       const response: { data: { product: Product } } = await axios.get('/api/products/product', {
//         params: { name },
//       });
//       dispatch(newRequestFormSlice.actions.getNewRequestFormSuccess(response.data.product));
//     } catch (error) {
//       console.error(error);
//       dispatch(newRequestFormSlice.actions.hasError(error));
//     }
//   };
// }
