/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  query,
  where,
  orderBy,
  limit,
  collection,
} from 'firebase/firestore';
// Types
import { TypePlatform } from "src/@types";
// Configurations
import { projectsPaths, employeesPaths } from "src/configs";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is generating query in order to fetch all projects for thee selected platforms
 * @param userUid
 * @param companyUid
 * @param platform
 * @param limitNumber
 * @param pageNumber
 */
export function queryFetchAllProjectsByPlatform(
  userUid: string,
  companyUid: string,
  platform: TypePlatform,
  limitNumber?: number,
  pageNumber?: number,
) {
  // Generating the forms' database reference
  const firestoreDb = getFirestore();
  const formsDbReference = collection(
    firestoreDb,
    projectsPaths.COLLECTION_PROJECTS
  );
  // Query for the forms
  return query(
    formsDbReference,
    where(projectsPaths.FIELD_ACTIVE, '==', true),
    // Only first 10 items are allowed to be checked
    where(projectsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Only platform based projects needs to be fetched and displayed
    where(projectsPaths.FIELD_PROJECT_TYPES, 'array-contains', platform.toLowerCase()),
    // // Only withe proper permission data needs to be fetched
    // where(projectsPaths.FIELD_PERMISSION, 'array-contains', userUid),
    //
    orderBy(projectsPaths.FIELD_CREATED, 'desc'),
    limit(limitNumber ?? 25),
  );
}

export function queryFetchAllTemplatesByProjectUid(project_uid: string) {
  const firestoreDb = getFirestore();
  const formsDbReference = collection(
    firestoreDb,
    'contract_templates'
  );
  // Query for the forms
  return query(
    formsDbReference,
    where(projectsPaths.FIELD_ACTIVE, '==', true),
    // Only first 10 items are allowed to be checked
    where('project_uid', '==', project_uid),
    // Only platform based projects needs to be fetched and displayed
    // // Only withe proper permission data needs to be fetched
    // where(projectsPaths.FIELD_PERMISSION, 'array-contains', userUid),
    //
    orderBy('order', 'asc'),
    // limit(limitNumber ?? 25),
  );
}

export function queryFetchAllTemplatesRTByProjectUid(project_uid: string) {
  const firestoreDb = getFirestore();
  const formsDbReference = collection(
    firestoreDb,
    'none'
  );
  // Query for the forms
  return query(
    formsDbReference,
    where(projectsPaths.FIELD_ACTIVE, '==', true),
    // Only first 10 items are allowed to be checked
    where('project_uid', '==', project_uid),
    where('visible', '==', true),
    // Only platform based projects needs to be fetched and displayed
    // // Only withe proper permission data needs to be fetched
    // where(projectsPaths.FIELD_PERMISSION, 'array-contains', userUid),
    //
    // orderBy('order', 'asc'),
    // limit(limitNumber ?? 25),
  );
}

export function queryTeam() {
  // Generating the forms' database reference
  const firestoreDb = getFirestore();
  const formsDbReference = collection(
    firestoreDb,
    employeesPaths.COLLECTION_EMPLOYEES
  );
  // Query for the forms
  return query(
    formsDbReference,
    where(projectsPaths.FIELD_ACTIVE, '==', true),
    // Only first 10 items are allowed to be checked
    where(projectsPaths.FIELD_COMPANY_UID, '==', 'pf0ArUxSiZNwqXZnun6rVZSkrRH2'),
    // Only platform based projects needs to be fetched and displayed
    // where(projectsPaths.FIELD_PROJECT_TYPES, 'array-contains', platform.toLowerCase()),
    // // // Only withe proper permission data needs to be fetched
    // // where(projectsPaths.FIELD_PERMISSION, 'array-contains', userUid),
    // //
    // orderBy(projectsPaths.FIELD_CREATED, 'desc'),
    // limit(limitNumber ?? 25),
  );
}