/**
 * @description - The file contains all available languages list and resources
 */
// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// @mui
import {deDE, enUS, frFR, hyAM, ruRU} from '@mui/material/locale';
import enLocales from "./en_content_bisflow";
import amLocales from "./am_content_bisflow";
import ruLocales from "./ru_content_bisflow";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export type TypeLanguageItem = {
  label: 'English' | 'Հայերեն | Armenian' | 'Русский | Russian',
  value: 'en' | 'am' | 'ru',
  systemValue: typeof enUS | typeof hyAM | typeof ruRU,
  icon: string,
};

export const LANGUAGES: TypeLanguageItem[] = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg',
  },
  {
    label: 'Русский | Russian',
    value: 'ru',
    systemValue: ruRU,
    icon: '/static/icons/ic_flag_ru.svg',
  },
  {
    label: 'Հայերեն | Armenian',
    value: 'am',
    systemValue: hyAM,
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg',
  },
];

/**
 * @description - The resources variable that contains all content for the translation
 */
export const resources = {
  en: { translations: enLocales },
  ru: { translations: ruLocales },
  am: { translations: amLocales }
}
