/**
 * @description - The Bisflow application content's translation
 */
// Common words like "Yes", "No", "Edit"
import enCommonContent from './en_common_words';
const enContentBisflow = {
  common: { ...enCommonContent },
  contracts: 'Контракты',
  newContract: 'Новый контракт',
  files: 'Файлы',
  active: 'Активный',
  archive: 'Архив',
  search: 'Поиск',
  internalSignature: 'Внутренняя подпись',
  externalSignature: 'Внешняя подпись',
  signed: 'Подписано',
  all: 'Все',
  byId: 'По идентификационному номеру клиента',
  byName: 'По названию контракта',
  file: 'Файл',
  device: 'Устройство',
  client: 'Клиент',
  time: 'Время',
  status: 'Статус',
  history: "История",
  show: 'Показать',
  delete: 'Удалить',
  cancel: 'Отмена',
  apply: 'Применить',
  customerIdExample: 'Идентификационный номер клиента - Например, 1234567',
  customerFullName: 'Полное имя клиента',
  customerEmail: 'Электронная почта клиента',
  contractType: 'Тип контракта',
  template: 'Шаблон',
  saveAsDraft: 'Сохранить как черновик',
  uploadCustomerPassport: 'Загрузить паспорт клиента',
  uploadContract: 'Загрузить контракт',
  uploadSupplements: 'Загрузить условия',
  edit: 'Редактировать',
  selectDevice: 'Выбрать устройство',
  customer: 'Клиент',
  add: 'Добавить',
  phoneNumber: 'Телефонный номер',
  phoneValidation: 'Телефонный номер должен быть из 7 цифр',
  fillCustomerDetails: 'Заполнить данные клиента',
  successSend: 'Успешно отправлено',
  sign: 'Подписать',
  signThird: 'Подпись третье лицо',
  customerActions: 'Действия клиента',
  confirm: 'Подтвердить',
  changeContactDetails: 'Изменить контактные данные',
  sendEmail: 'Отправить электронное письмо',
  sendSms: 'Отправить СМС',
  download: 'Скачать',
  erase: 'Стереть',
  saveAsTemplate: 'Сохранить как шаблон',
  signByTemplate: 'Подписать по шаблону',
  signatureActions: 'Действия подписи',
  moveMySignature: 'Переместить мою подпись',
  moveCustomerSignature: 'Переместить подпись клиента',
  reset: 'Отмена',
  showOpenContracts: 'Показать открытые контракты',
  opened: 'Opened',
  action: 'Действие',
  close: 'Закрыть',
  oldProcess: 'Старый процесс',
  newContractWithOldProcess: 'Նոր գործարք Հին պրոցեսով',
};

export default enContentBisflow;
