/**
 * @description - The all services that are related to the requests data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  collection,
  Timestamp
} from 'firebase/firestore';
// Local types
import { TypeFormNewRequest } from 'src/@types';
// Paths
import { formsPaths } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is fetching new request forms from the server
 * @param onSuccess - On success method, is executing whenever the method completed successfully. Passing the result
 * list to the void function that will be used in the parent.
 * @param onFail - On failed method, is executing whenever there is any issue during the data fetching
 * @param userUid - The selected user uid which is doing the fetching process
 * @param companies - The companies uid list, that need to be checked for the query
 */
export function fetchNewRequestForms(
  onSuccess: (newRequestsForms: TypeFormNewRequest[]) => void,
  onFail: (error: Error | string) => void,
  userUid?: string,
  companies?: string[],
) {
  // Only if the user exists and the user correspond to any company
  if (userUid && companies && companies.length > 0) {
    // Generating the forms' database reference
    const firestoreDb = getFirestore();
    const formsDbReference = collection(
      firestoreDb,
      formsPaths.COLLECTION_FORMS
    );
    // Query for the forms
    const newRequestsFormsQuery = query(
      formsDbReference,
      where(formsPaths.FIELD_ACTIVE, '==', true),
      // Only first 10 items are allowed to be checked
      where(formsPaths.FIELD_COMPANY_UID, 'in', companies.slice(0, 10)),
      where(formsPaths.FIELD_FORM_TYPE, '==', formsPaths.VALUE_FORM_TYPE__REQUEST),
      orderBy(formsPaths.FIELD_CREATED, 'desc'),
      limit(50)
    );
    // Trying to fetch the request forms
    getDocs(newRequestsFormsQuery)
      .then((querySnapshot) => {
        const listOfNewRequestForms: TypeFormNewRequest[] = [];
        querySnapshot.forEach((newRequestFormSnapshot) => {
          const newRequestFormData = newRequestFormSnapshot.data();
          if (newRequestFormData) {
            listOfNewRequestForms.push({
              uid: newRequestFormData.uid ?? 'N/A',
              display_name: newRequestFormData.display_name ?? 'N/A',
              company_uid: newRequestFormData.company_uid ?? 'N/A',
            })
          }
        });
        onSuccess(listOfNewRequestForms);
      })
      .catch((error) => onFail(error.message));
  } else {
    onFail('User or company is not defined');
  }
}
