/**
 * @description - The signature drawer view component. Allowing to draw a
 * signature
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// React
import { MutableRefObject, useEffect, useRef } from 'react';
// Material UI's
import { Button } from '@mui/material';
// The signature canvas external library
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas-react17-compatible';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

interface SignatureDrawerProps {
  onDialogClick: (base64Image: any) => void;
  onSaveTemplateClick: (base64Image: any) => void;
  onUseTemplate: () => void;
  onCancelDialog: () => void;
  status?: string;
}

export default function SignatureDrawerDevice({
                                          onDialogClick,
                                          onSaveTemplateClick,
                                          onUseTemplate,
                                          onCancelDialog,
                                          status
                                        }: SignatureDrawerProps) {
  const getWidth = () => {
    return window.innerWidth;
  };

  useEffect(() => {
    if (status === 'confirm') {
      const currentSignature = padRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      if (currentSignature?.length > 200) {
        onDialogClick(currentSignature);
      }
    }
  }, [status]);

  const getHeight = () => {
    return window.innerHeight - 50;
  };

  let padRef = useRef() as MutableRefObject<any>;
  const clear = () => {
    if (padRef) {
      padRef.current.clear();
    }
  };
  const save = () => {
    const currentSignature = padRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    console.log(currentSignature);
    onDialogClick(currentSignature);
  };

  const saveSignature = () => {
    const currentSignature = padRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');
    onSaveTemplateClick(currentSignature);
  }
  const onCancel = () => {
    onCancelDialog();
  };
  return (
    <div style={{ width: getWidth(), height: getHeight() }}>
      {/*<Button sx={{ margin: 2 }} onClick={save}>*/}
      {/*  Ստորագրել*/}
      {/*</Button>*/}
      {/*<Button sx={{ margin: 2 }} onClick={onUseTemplate}>*/}
      {/*  ՍՏՈՐԱԳՐԵԼ ՆՄՈՒՇԻ ՍՏՈՐԱԳՐՈՎ*/}
      {/*</Button>*/}
      {/*<Button sx={{ margin: 2 }} onClick={onCancel} color={'error'}>*/}
      {/*  Չեղարկել*/}
      {/*</Button>*/}
      {/*<Button sx={{ margin: 2 }} onClick={clear} color={'inherit'}>*/}
      {/*  Ջնջել*/}
      {/*</Button>*/}
      {/*<Button sx={{ margin: 2 }} onClick={saveSignature} color={'inherit'}>*/}
      {/*  Պահպանել որպես ձևանմուշ*/}
      {/*</Button>*/}
      <SignatureCanvas
        penColor="blue"
        canvasProps={{
          width: getWidth(),
          height: getHeight(),
          className: 'sigCanvas'
        }}
        ref={padRef}
      />
    </div>
  );
}
