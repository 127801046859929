/**
 * @description - The parties selection component. Needed in order to select counterparties for the document management
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Stack, Divider, Typography, Button } from '@mui/material';
// Local Hooks
import { useLocales, useResponsive, useToggle } from 'src/hooks';
// Local components
import { Iconify } from "src/components";
// Models / Types
import { ModelContact } from 'src/@types';
// Local dialogs
import { DocumentContactsListDialog, DocumentNewContactDialog } from 'src/pages/docs/dialogs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The properties for the parties contact form
 */
interface PartiesContactFormProps {
  fromInitialContacts?: ModelContact[];
  toInitialContacts?: ModelContact[];
}

/**
 * @description - The contact info item view
 * @param display_name
 * @param email
 * @constructor
 */
function PartyContactInfoComponent({ display_name, email }: ModelContact) {
  return (
    <div>
      <Typography variant="subtitle2">{display_name}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>Email: {email}</Typography>
    </div>
  )
}

/**
 * @description - The method is generating the parties view in order to select the counterparties of the document
 * @constructor
 */
export default function PartiesContactForm({ fromInitialContacts, toInitialContacts } : PartiesContactFormProps) {
  // Getting the screen size in order to display the in a correct way
  const upMd = useResponsive('up', 'md');
  const { translate } = useLocales();
  // Dialog view
  const { toggle: openFrom, onOpen: onOpenFrom, onClose: onCloseFrom } = useToggle();
  const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

  // The methods in order to attach the form listeners
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  // Attaching listener
  const values = watch();
  const { from, to } = values;

  return (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: 'column', md: 'row' }}
      divider={
        <Divider
          flexItem
          orientation={upMd ? 'vertical' : 'horizontal'}
          sx={{ borderStyle: 'dashed' }}
        />
      }
      sx={{ p: 3 }}
    >
      {/* From Form */}
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            {translate('device')}:
          </Typography>
          <Button size="small" startIcon={<Iconify icon="eva:edit-fill" />} onClick={onOpenFrom}>
            {translate('edit')}
          </Button>
        </Stack>
        <PartyContactInfoComponent {...from} />
        {fromInitialContacts
          ? (
            <DocumentContactsListDialog
              open={openFrom}
              selected={(selectedId: string) => from?.uid === selectedId}
              onClose={onCloseFrom}
              onSelect={(document) => setValue('from', document)}
              contactsOptions={fromInitialContacts}
            />
          )
          : (
            <DocumentNewContactDialog
              open={openFrom}
              onClose={onCloseFrom}
              onSelect={(document) => setValue('from', document)}
            />
          )
        }
      </Stack>
      {/* To Form */}
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            {translate('customer')}:
          </Typography>
          <Button size="small" startIcon={<Iconify icon="eva:edit-fill" />} onClick={onOpenTo }>
            {translate('add')}
          </Button>
        </Stack>
        <PartyContactInfoComponent {...to} />
        <DocumentNewContactDialog
          open={openTo}
          onClose={onCloseTo}
          onSelect={(document) => setValue('to', document)}
        />
      </Stack>
    </Stack>
  )
}
