/**
 * @description - The file contains the view and form in order to create a documentation.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useEffect, useMemo, useCallback, useState } from 'react';
// Notification bar
import { useSnackbar } from "notistack";
// Material Components
import { Card, Stack, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// PDF viewer
import { Viewer } from '@react-pdf-viewer/core';
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// Form
import { useForm } from "react-hook-form";
// Redux related
import { useDispatch, useSelector } from "src/redux/store";
// Hooks
import { useAuth, useLocales, useLocalStorage } from "src/hooks";
// Local components
import { FormProvider, RHFSelect, LoadingScreen, RHFUploadSingleFile } from 'src/components';
import PartiesContactForm from '../components/parties';
// Document types
import { TypeDocumentView, ModelNewContractForm } from 'src/@types';
// Slices/services
import { getPlatformProjects, createContractByAttachment } from "src/redux/slices";
import {
  fetchAllContractTemplatesByProjectUid,
  fetchAllContractTemplatesRTByProjectUid
} from "src/services";
import { fetchDepartments, fetchAllEmployees } from 'src/services/departments';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The interface for the document create view
 */
interface DocCreateViewProps {
  pageType: TypeDocumentView;
}

/**
 * @description - The method is a Document Creation View component that's need to create a documentation, contract
 * or act.
 * @param pageType - The page type string format, in order to make proper document creation process.
 * @constructor
 */
export default function DocCreateTemplateRTView({ pageType }: DocCreateViewProps ) {
  // ======================================== THE VIEW DATA INITIALIZATION ========================================== //
  // Notification bar
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  // Dispatch
  const dispatch = useDispatch();
  // Get user and companies
  const { user } = useAuth();
  // Selected company
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', undefined);
  // The global state
  const { projects, isLoadingProjects, projectFetchingError } = useSelector((state) => state.platformProjects);
  const { isLoadingContracts, contractFetchingError, contract } = useSelector((state) => state.contracts);

  // ======================================== DOCUMENT SCHEMA and FORM SETUP ======================================== //

  /**
   * @description - The schema for the form provider.
   */
  const NewDocumentSchema = Yup.object().shape({
    project_uid: Yup.string().required('Project Field is required to be set'),
    flow_uid: Yup.string().required('Flow Field is required to be set'),
    attachment: Yup.mixed().nullable(),
    documents: Yup.mixed().nullable(),
    supplements: Yup.mixed().nullable(),
    // Checking the template_uid to be selected if no attachment exist.
    template_uid: Yup.string().when('attachment', {
      is: null,
      then: Yup.string().required('Template or attachment required'),
      otherwise: Yup.string().nullable()
    }),
    from: Yup.mixed().nullable().required('Need to select a person who are responsible to sign'),
    to: Yup.mixed().nullable().required('Need to select a person who are responsible to sign'),
  });
  /**
   * @description - Initializing the default value of the schema
   */
  const newDocumentDefaultValues = useMemo(
    () => ({
      project_uid: undefined,
      flow_uid: 'test',
      attachment: null,
      documents: null,
      supplements: null,
      template_uid: null,
      display_name: '',
      from: undefined,
      to: undefined,
    }),
    [pageType]
  );
  /**
   * @description - Initializing the methods for the current form
   */
  const methods = useForm<ModelNewContractForm>({
    resolver: yupResolver(NewDocumentSchema),
    defaultValues: newDocumentDefaultValues,
  });
  // Getting values sub-methods in order to attach the form value change listener
  const {
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  // Creating a listener to the documentation values change

  // =================================================== HANDLERS =================================================== //
  /**
   * @description - The method is listening to the callback of the state, and if
   * the user uploaded the file into the drop zone then it will trigger set
   * state method
   */
  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.path) {
      setValue('attachment', {
        file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const handleDropSupplement = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.path) {
      setValue('supplements', {
        file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const handleDocumentDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.path) {
      const fileType = file.type;
      setValue('documents', {
        file,
        preview: URL.createObjectURL(file),
        type: fileType,
      });
    }
  }, []);

  /**
   * @description - The method is submitting the draft version of the document
   */
  const handleSaveAsDraft = () => {
    if (values?.project_uid && (values?.attachment || values?.template_uid) && values?.from && values?.to) {
      // @ts-ignore
      if (values?.attachment && values?.to?.clientId?.length === 7) {
        dispatch(createContractByAttachment(
        // console.log(
          selectedCompany.uid,
          values.project_uid,
          values.template_uid,
          values.attachment.file.name,
          allContractTemplates?.filter((template) => template.uid === values.template_uid)?.[0]?.display_name ?? values.attachment.file.name,
          values.attachment.file,
          values.from,
          values.to,
          user?.uid,
          values.documents?.type,
          values?.documents?.file,
          values?.supplements?.file,
        ))
        // )
      }
    }
  };

  // =============================================== THE VIEW SETUP ================================================= //
  const values = watch();
  const [allContractTemplates, setAllContractTemplates] = useState<Record<string, any>[]>([]);
  const [allDepartments, setAllDepartments] = useState<Record<string, any>[]>([]);
  const [allEmployees, setAllEmployees] = useState<Record<string, any>[]>([]);
  /**
   * @description - attaching the listener for the projects
   */
  useEffect(() => {
    if (user?.uid && selectedCompany?.uid) {
      // Fetching all available projects
      dispatch(getPlatformProjects(user.uid, selectedCompany.uid));
      fetchDepartments(setAllDepartments);
      fetchAllEmployees(setAllEmployees);
    }
  }, [dispatch, user, selectedCompany]);
  // Listener for the contract
  useEffect(() => {
    if (typeof contract === 'string') {
      window.location.href = '/app/active'
    }
  }, [contract]);
  /**
   * @description - attaching the listener for the selected project's templates
   */
  useEffect(() => {
    if (values?.project_uid && user?.uid && selectedCompany?.uid) {
      // Fetching all available projects
      // dispatch(getPlatformProjects(user.uid, selectedCompany.uid));
      fetchAllContractTemplatesRTByProjectUid(values.project_uid, setAllContractTemplates);
    }
  }, [values?.project_uid]);
  /**
   * @description - The listener for any error
   */
  useEffect(() => {
    if (projectFetchingError) {
      enqueueSnackbar(
        `ERROR: "${typeof projectFetchingError  === 'string' ? projectFetchingError : projectFetchingError.message}"`,
        { variant: 'error' }
      );
    }
  }, [projectFetchingError])

  const parsePartiesView = () => {
    const ajapnyakParties = (
      <PartiesContactForm fromInitialContacts={[
        {
          user_uid: 'RzyEGaifcNZqI7wA5HryAdhveA23',
          uid: 'RzyEGaifcNZqI7wA5HryAdhveA23',
          display_name: '1',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device5@rtarmenia.am'
        },
        {
          user_uid: 'pvfp4nx1PRev6tiLF2itF8NEzy13',
          uid: 'pvfp4nx1PRev6tiLF2itF8NEzy13',
          display_name: '2',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device6@rtarmenia.am'
        },
      ]} />
    );

    const yerevanCenterParties = (
      <PartiesContactForm fromInitialContacts={[
        {
          user_uid: 'YdvSMpTiP2Y6GMb9P2GwNXQqQzp2',
          uid: 'YdvSMpTiP2Y6GMb9P2GwNXQqQzp2',
          display_name: '1',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device2@rtarmenia.am'
        },
        {
          user_uid: 'YhPtEXjqKbXl3ngpU9O5mXhYTfo2',
          uid: 'YhPtEXjqKbXl3ngpU9O5mXhYTfo2',
          display_name: '2',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device3@rtarmenia.am'
        },
        {
          user_uid: 'aMzxiCmf7XWMbqayoEFQDVCzjDs2',
          uid: 'aMzxiCmf7XWMbqayoEFQDVCzjDs2',
          display_name: '3',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device4@rtarmenia.am'
        },
        {
          user_uid: 'yzTRkDJHyLb9QSTbUnG2yYZtX803',
          uid: 'yzTRkDJHyLb9QSTbUnG2yYZtX803',
          display_name: 'Device 1',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device1@rtarmenia.am'
        },
      ]} />
    );

    const shengavitParties = (
      <PartiesContactForm fromInitialContacts={[
        {
          user_uid: 'lsCrPyNABRMM5o0sD2rxQiQFQjE3',
          uid: 'lsCrPyNABRMM5o0sD2rxQiQFQjE3',
          display_name: '1',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device7@rtarmenia.am'
        },
        {
          user_uid: '8kSfaDCTK4OH5PNDqnIhngFuOmu1',
          uid: '8kSfaDCTK4OH5PNDqnIhngFuOmu1',
          display_name: '2',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device8@rtarmenia.am'
        },
      ]} />
    )

    const norqParties = (
      <PartiesContactForm fromInitialContacts={[
        {
          user_uid: 'ES2qzZlkGeQFBG6c1AfArzp2gGs1',
          uid: 'ES2qzZlkGeQFBG6c1AfArzp2gGs1',
          display_name: '1',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device9@rtarmenia.am'
        },
        {
          user_uid: 'd5GdzM6PBPWV0wqbWl7qanylyX22',
          uid: 'd5GdzM6PBPWV0wqbWl7qanylyX22',
          display_name: '2',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device10@rtarmenia.am'
        },
      ]} />
    );

    const erebuniParties = (
      <PartiesContactForm fromInitialContacts={[
        {
          user_uid: 'nCVU8AdEjLcNluNdWSzZWGZZIah1',
          uid: 'nCVU8AdEjLcNluNdWSzZWGZZIah1',
          display_name: '1',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device11@rtarmenia.am'
        },
        {
          user_uid: 'yxTCyeojZnf4NpV98Hl1lCiZjjO2',
          uid: 'yxTCyeojZnf4NpV98Hl1lCiZjjO2',
          display_name: '2',
          company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
          email: 'device12@rtarmenia.am'
        },
      ]} />
    )

    if (allEmployees?.length > 0 && allDepartments?.length > 0 && user?.uid) {
      const currentEmployee = allEmployees?.find((employee) => employee.user_uid === user.uid);
      if (currentEmployee) {
        const currentDepartment = allDepartments?.find((department) => department?.uid === currentEmployee?.department_uid);
        if (currentDepartment) {
          switch (currentDepartment.uid) {
            case "y0WSiFDxRf5fksgQW3Xm":
              return yerevanCenterParties;
            case "JmY9vFlOvZQ1r9trHVCX":
                return ajapnyakParties;
            case "tm6UNwJ6Eu4J0KqfuAOx":
              return shengavitParties;
            case "7WfiWsJH11ER1khe9B6z":
              return norqParties;
            case "zuqd7ltUGHIpHRj5nU6t":
              return erebuniParties;
            default:
              return <PartiesContactForm fromInitialContacts={[]} />;
          }
        }
      }
    }

    // const

    // return (
    //   {(values?.attachment?.preview || values?.template_uid) && ['6zyai9U97yd1cuh3swAgdnplvn82', '2mrDIwxyRyYhhXg9jLSFMTlY1wG2'].includes(user?.uid ?? '') && (
    //
    //   )}
    // {(values?.attachment?.preview || values?.template_uid) && ['oFQTE3GSF2auaIMTMSGDzHso2vj2', 'VLEhBLjqc0hPlDFMeSjfNlwSSOE2'].includes(user?.uid ?? '') && (
    //   <PartiesContactForm fromInitialContacts={[
    //     {
    //       user_uid: 'lsCrPyNABRMM5o0sD2rxQiQFQjE3',
    //       uid: 'lsCrPyNABRMM5o0sD2rxQiQFQjE3',
    //       display_name: '1',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device7@rtarmenia.am'
    //     },
    //     {
    //       user_uid: '8kSfaDCTK4OH5PNDqnIhngFuOmu1',
    //       uid: '8kSfaDCTK4OH5PNDqnIhngFuOmu1',
    //       display_name: '2',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device8@rtarmenia.am'
    //     },
    //   ]} />
    // )}
    // {(values?.attachment?.preview || values?.template_uid) && ['WDR2rT7VMVWmj7zYbRkSX9ZJyjz1', 'MfY9a2cKdsdirH1zG2EFgj91m652'].includes(user?.uid ?? '') && (
    //   <PartiesContactForm fromInitialContacts={[
    //     {
    //       user_uid: 'ES2qzZlkGeQFBG6c1AfArzp2gGs1',
    //       uid: 'ES2qzZlkGeQFBG6c1AfArzp2gGs1',
    //       display_name: '1',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device9@rtarmenia.am'
    //     },
    //     {
    //       user_uid: 'd5GdzM6PBPWV0wqbWl7qanylyX22',
    //       uid: 'd5GdzM6PBPWV0wqbWl7qanylyX22',
    //       display_name: '2',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device10@rtarmenia.am'
    //     },
    //   ]} />
    // )}
    // {(values?.attachment?.preview || values?.template_uid) && ['GvXrEDMdwXXWzKIzMHe4TmOSpQr2'].includes(user?.uid ?? '') && (
    //   <PartiesContactForm fromInitialContacts={[
    //     {
    //       user_uid: 'nCVU8AdEjLcNluNdWSzZWGZZIah1',
    //       uid: 'nCVU8AdEjLcNluNdWSzZWGZZIah1',
    //       display_name: '1',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device11@rtarmenia.am'
    //     },
    //     {
    //       user_uid: 'yxTCyeojZnf4NpV98Hl1lCiZjjO2',
    //       uid: 'yxTCyeojZnf4NpV98Hl1lCiZjjO2',
    //       display_name: '2',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device12@rtarmenia.am'
    //     },
    //   ]} />
    // )}
    // {(values?.attachment?.preview || values?.template_uid) && !['WDR2rT7VMVWmj7zYbRkSX9ZJyjz1', 'MfY9a2cKdsdirH1zG2EFgj91m652' , '6zyai9U97yd1cuh3swAgdnplvn82', '2mrDIwxyRyYhhXg9jLSFMTlY1wG2', 'oFQTE3GSF2auaIMTMSGDzHso2vj2', 'VLEhBLjqc0hPlDFMeSjfNlwSSOE2', 'GvXrEDMdwXXWzKIzMHe4TmOSpQr2'].includes(user?.uid ?? '') && (
    //   <PartiesContactForm fromInitialContacts={[
    //     {
    //       user_uid: 'YdvSMpTiP2Y6GMb9P2GwNXQqQzp2',
    //       uid: 'YdvSMpTiP2Y6GMb9P2GwNXQqQzp2',
    //       display_name: '1',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device2@rtarmenia.am'
    //     },
    //     {
    //       user_uid: 'YhPtEXjqKbXl3ngpU9O5mXhYTfo2',
    //       uid: 'YhPtEXjqKbXl3ngpU9O5mXhYTfo2',
    //       display_name: '2',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device3@rtarmenia.am'
    //     },
    //     {
    //       user_uid: 'aMzxiCmf7XWMbqayoEFQDVCzjDs2',
    //       uid: 'aMzxiCmf7XWMbqayoEFQDVCzjDs2',
    //       display_name: '3',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device4@rtarmenia.am'
    //     },
    //     {
    //       user_uid: 'yzTRkDJHyLb9QSTbUnG2yYZtX803',
    //       uid: 'yzTRkDJHyLb9QSTbUnG2yYZtX803',
    //       display_name: 'Device 1',
    //       company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //       email: 'device1@rtarmenia.am'
    //     },
    //     // {
    //     //   user_uid: 'SyejRFbPAwg8eACOtc5wL1AnsRn1',
    //     //   uid: 'SyejRFbPAwg8eACOtc5wL1AnsRn1',
    //     //   display_name: 'Device 1',
    //     //   company_uid: 'pf0ArUxSiZNwqXZnun6rVZSkrRH2',
    //     //   email: 'testdevice@rtarmenia.am'
    //     // },
    //   ]} />
    // )}
    // )

    return <PartiesContactForm fromInitialContacts={[]} />;
  }
  /**
   * @description - Whenever there is fetching process need to return the loader screen
   */
  if (isLoadingProjects || isLoadingContracts) {
    return <LoadingScreen />;
  }
  /**
   * @description - The document creation view
   */
  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <RHFSelect
                  fullWidth
                  name="project_uid"
                  label={translate('contractType')}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  value={values?.project_uid ?? ''}
                >
                  {projects?.map((currentProject) => (
                    <MenuItem
                      key={currentProject.uid}
                      value={currentProject.uid}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {currentProject.display_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              {values?.project_uid?.length > 0 && allContractTemplates?.length > 0 && (
                <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFSelect
                    fullWidth
                    name="template_uid"
                    label={translate('template')}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    value={values?.template_uid ?? ''}
                  >
                    {allContractTemplates.map((templateData) => (<MenuItem
                      key={templateData.uid}
                      value={templateData.uid}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {templateData.display_name}
                    </MenuItem>))}
                  </RHFSelect>
                </Stack>
              )}
              {values?.project_uid?.length > 0 && (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <RHFUploadSingleFile
                  name={'attachment'}
                  maxSize={209715200} // 25MB in bites
                  accept="application/pdf" // Accepted only xlsx format
                  onDrop={handleDrop}
                  customText={translate('uploadContract')}
                />
              </Stack>)}
              {values?.project_uid?.length > 0 && (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <RHFUploadSingleFile
                  name={'supplements'}
                  maxSize={209715200} // 25MB in bites
                  accept="application/pdf" // Accepted only xlsx format
                  onDrop={handleDropSupplement}
                  customText={translate('uploadSupplements')}
                />
              </Stack>)}
              {values?.project_uid?.length > 0 && (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <RHFUploadSingleFile
                  name={'documents'}
                  maxSize={209715200} // 25MB in bites
                  accept="application/pdf, image/jpeg, image/png" // Accepted only xlsx format
                  onDrop={handleDocumentDrop}
                  customText={translate('uploadCustomerPassport')}
                />
              </Stack>)}
              {values?.project_uid?.length > 0 && (<Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  variant="contained"
                  loading={isSubmitting || isLoadingContracts}
                  onClick={handleSubmit(handleSaveAsDraft)}
                >
                  {translate('saveAsDraft')}
                </LoadingButton>
              </Stack>)}
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          {(values?.attachment?.preview || values?.template_uid) && user?.uid && parsePartiesView()}
          {values?.attachment?.preview && (
            <div
              style={{
                height: '750px',
                padding: '18px'
              }}
            >
              <Viewer fileUrl={values.attachment.preview} />
            </div>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}
