/**
 * @description - The dialog view to pick contacts
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useEffect, useState } from 'react';
// @mui
import { Dialog, TextField, Stack, Typography, Button, DialogActions, Box} from '@mui/material';
import { LoadingButton } from "@mui/lab";
// Types
import { ModelContact } from 'src/@types';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useSnackbar } from "notistack";
import { useLocales } from "src/hooks";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The props for the dialog
 */
type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSelect: (contact: ModelContact | null) => void;
  currentContact?: ModelContact;
};

/**
 * @description - The method is a dialog view for the document contact selection
 * @param open
 * @param onClose
 * @param onSelect
 * @constructor
 */
export default function DocumentNewContactDialog({
  open,
  onClose,
  onSelect,
  currentContact
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const handleSelect = (contact: ModelContact) => {
    onSelect(contact);
    onClose();
  };

  const [counterparty, setCounterparty] = useState({
    display_name: '',
    email: '',
    company_uid: '',
    user_uid: '',
    clientId: ''
  })

  useEffect(() => {
    if (currentContact) {
      setCounterparty({
        ...currentContact,
        clientId: currentContact?.clientId ?? 'na'
      });
    }
  }, [currentContact]);

  // @ts-ignore
  const handleChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and limit length to 7
    if (/^\d{0,7}$/.test(value)) {
      setCounterparty({
        ...counterparty,
        clientId: value
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6">{translate('fillCustomerDetails')}</Typography>
      </Stack>
      <Stack sx={{ p: 1.5}} spacing={2}>
        <TextField
          id="standard-basic"
          label={translate('customerIdExample')}
          variant="standard"
          type="number"
          value={counterparty.clientId}
          onChange={handleChange}
          inputProps={{ minLength: 7, maxLength: 7, pattern: "\\d*" }} // This ensures numeric keyboard on mobile
          // devices
        />
        {/*<TextField id="standard-basic" label="Հաճախորդի ID" variant="standard" onChange={(e) => setCounterparty({*/}
        {/*  ...counterparty,*/}
        {/*  clientId: e.target.value*/}
        {/*})} />*/}
        <TextField id="standard-basic" value={counterparty.display_name} label={translate('customerFullName')} variant="standard" onChange={(e) => setCounterparty({
          ...counterparty,
          display_name: e.target.value
        })} />
        <TextField id="standard-basic" value={counterparty.email} label={translate('customerEmail')} variant="standard" onChange={(e) => setCounterparty({
          ...counterparty,
          email: e.target.value
        })} />
        <PhoneInput
          defaultCountry="am"
          value={counterparty.company_uid}
          placeholder={translate('phoneNumber')}
          onChange={(phone) => setCounterparty({
            ...counterparty,
            company_uid: phone
          })}
        />
        {/*<TextField id="standard-basic" label="Հեռախոսահամար" variant="standard" onChange={(e) => setCounterparty({*/}
        {/*  ...counterparty,*/}
        {/*  company_uid: e.target.value*/}
        {/*})} />*/}
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {translate('cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={false} onClick={() => {
          if (counterparty.clientId.length === 7) {
            handleSelect({
              uid: '123',
              ...counterparty,
            });
          } else {
            enqueueSnackbar(translate('phoneValidation'), { variant: 'warning' });
          }
        }}>
          {currentContact ? translate('edit'): translate('add')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
