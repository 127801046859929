/**
 * @description - The Bisflow application content's translation
 */
// Common words like "Yes", "No", "Edit"
import enCommonContent from './en_common_words';
const enContentBisflow = {
  common: { ...enCommonContent },
  contracts: 'Գործարքներ',
  newContract: 'Նոր գործարք',
  newContractWithOldProcess: 'Նոր գործարք Հին պրոցեսով',
  files: 'Ֆայլեր',
  active: 'Ընթացիկ',
  archive: 'Արխիվ',
  search: 'Որոնում',
  internalSignature: 'Ներքին Ստորագրություն',
  externalSignature: 'Միակողմանի Ստորագրված',
  signed: 'Ստորագրված',
  all: 'Բոլորը',
  byId: 'Հաճախորդի ID-ով',
  byName: 'Պայմանագրի անվանմամբ',
  file: 'Ֆայլ',
  device: 'Սարք',
  client: 'Հաճախորդ',
  time: 'Ժամանակ',
  status: 'Կարգավիճակ',
  history: "Պատմություն",
  show: 'Ցուցադրում',
  delete: 'Ջնջել',
  cancel: 'Չեղարկել',
  apply: 'Հաստատել',
  customerIdExample: 'Հաճախորդի ID - Օրինակ. 1234567',
  customerFullName: 'Հաճախորդի լրիվ անվանումը',
  customerEmail: 'Հաճախորդի էլփոստի հասցեն',
  contractType: 'Գործարքի տեսակը',
  template: 'Տիպը',
  saveAsDraft: 'Պահել որպես սևագիր',
  uploadCustomerPassport: 'Վերբեռնեք հաճախորդի անձնագիրը/ID',
  uploadContract: 'Վերբեռնել գործարքը',
  uploadSupplements: 'Վերբեռնեք պայմանները',
  edit: 'Խմբագրել',
  selectDevice: 'Ընտրեք Սարքը',
  customer: 'Հաճախորդ',
  add: 'Ավելացնել',
  phoneNumber: 'Հեռախոսահամար',
  phoneValidation: 'Հեռախոսահամարը պետք է լինի 7 նիշ',
  fillCustomerDetails: 'Լրացրեք հաճախորդի տվյալները',
  successSend: 'Հաջողությունն ուղարկված է',
  sign: 'Ստորագրել',
  signThird: 'Երրորդ կողմի ստորագրությունը',
  customerActions: 'Հաճախորդի գործողություններ',
  confirm: 'Հաստատել',
  changeContactDetails: 'Փոխել կոնտակտային տվյալները',
  sendEmail: 'Ուղարկել նամակ',
  sendSms: 'Ուղարկել SMS',
  download: 'Բեռնել',
  erase: 'Ջնջել',
  saveAsTemplate: 'Պահպանել որպես ձևանմուշ',
  signByTemplate: 'Ստորագրել նմուշով',
  signatureActions: 'Ստորագրության գործողություններ',
  moveMySignature: 'Տեղափոխիր իմ ստորագրությունը',
  moveCustomerSignature: 'Տեղափոխել հաճախորդի ստորագրությունը',
  reset: 'Վերականգնել',
  showOpenContracts: 'Ցույց տալ բաց պայմանագրերը',
  opened: 'Opened',
  action: 'Գործողություն',
  close: 'փակել',
  oldProcess: 'Հին գործընթաց',
};

export default enContentBisflow;
