/**
 * @description - The firestore database paths
 */

// Common
const FIELD_UID = 'uid';
const FIELD_COMPANY_UID = 'company_uid';
const FIELD_USER_UID = 'user_uid';
const FIELD_ACTIVE = 'active';
const FIELD_CREATED = 'created';
const FIELD_UPDATED = 'updated';
const FIELD_VISIBLE = 'visible';
const FIELD_PERMISSION = 'permission';

// Employees Paths
const COLLECTION_EMPLOYEES = 'employees';
const FIELD_PLATFORMS = 'platforms';

// Employees paths
export const employeesPaths = {
  COLLECTION_EMPLOYEES,
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
  FIELD_USER_UID,
  FIELD_PLATFORMS,
}

// User based
const COLLECTION_USERS = 'users';

// User paths
export const userPaths = {
  COLLECTION_USERS,
  FIELD_UID,
};

// Forms based
const COLLECTION_FORMS = 'forms';
const FIELD_FORM_TYPE = 'form_type';
const VALUE_FORM_TYPE__REQUEST = 'request';

export const formsPaths = {
  COLLECTION_FORMS,
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
  FIELD_FORM_TYPE,
  FIELD_CREATED,
  VALUE_FORM_TYPE__REQUEST,
}

// Projects based
const COLLECTION_PROJECTS = 'projects';
const FIELD_PROJECT_TYPES = 'project_types';
const FIELD_PROJECT_UID = 'project_uid';

export const projectsPaths = {
  COLLECTION_PROJECTS,
  FIELD_ACTIVE,
  FIELD_PROJECT_TYPES,
  FIELD_PERMISSION,
  FIELD_COMPANY_UID,
  FIELD_CREATED,
}

const COLLECTION_DOCUMENTS = 'documents';
const COLLECTION_CONTRACTS = 'contracts';
const COLLECTION_ACTS = 'acts';
const FIELD_DOCUMENT_STATUS = 'document_status';
export const documentsPaths = {
  COLLECTION_DOCUMENTS,
  COLLECTION_CONTRACTS,
  COLLECTION_ACTS,
  FIELD_COMPANY_UID,
  FIELD_PROJECT_UID,
  FIELD_DOCUMENT_STATUS,
  FIELD_CREATED,
  FIELD_VISIBLE,
}
