/**
 * @description - Logo component that is displaying SVG
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Material UI's
import { Box, BoxProps } from '@mui/material';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The Logo Color interface, need 3 color scheme in order to draw
 * the gradient logo for bisflow.
 */
export interface LogoBisflowColorProps extends BoxProps {
  lighter: string;
  light: string;
  primary: string;
  dark: string;
  darker: string;
}

/**
 * @description - The Bisflow Logo based on external coloring
 * @param lighter
 * @param light
 * @param primary
 * @param dark
 * @param darker
 * @param boxProps
 * @constructor
 */
export function LogoBisflow({
  lighter,
  light,
  primary,
  dark,
  darker,
  ...boxProps
}: LogoBisflowColorProps) {
  // Selected sx property
  const { sx } = boxProps;
  // random id to pass to id in order to not have same color for different
  // instances
  const instancePrefix = Math.random();
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 450 450"
      >
        <defs>
          <linearGradient
            spreadMethod="pad"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
            id={`${instancePrefix}_svg_30`}
          >
            <stop offset="0" stopOpacity="0.99609" stopColor={lighter} />
            <stop offset="1" stopOpacity="0.99609" stopColor={light} />
          </linearGradient>
          <linearGradient
            spreadMethod="pad"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
            id={`${instancePrefix}_svg_31`}
          >
            <stop offset="0" stopOpacity="0.99609" stopColor={primary} />
            <stop offset="1" stopOpacity="0.99609" stopColor={light} />
          </linearGradient>
          <linearGradient
            spreadMethod="pad"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
            id={`${instancePrefix}_svg_32`}
          >
            <stop offset="0" stopOpacity="0.99609" stopColor={dark} />
            <stop offset="1" stopOpacity="0.99609" stopColor={primary} />
          </linearGradient>
          <linearGradient
            spreadMethod="pad"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
            id={`${instancePrefix}_svg_33`}
          >
            <stop offset="0" stopOpacity="0.99609" stopColor={dark} />
            <stop offset="1" stopOpacity="0.99609" stopColor={darker} />
          </linearGradient>
          <linearGradient
            spreadMethod="pad"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
            id={`${instancePrefix}_svg_34`}
          >
            <stop offset="0" stopOpacity="0.99609" stopColor={primary} />
            <stop offset="1" stopOpacity="0.99609" stopColor={darker} />
          </linearGradient>
        </defs>
        <g>
          <title>Layer 1</title>
          <ellipse
            strokeWidth="10"
            ry="80"
            rx="80"
            id="svg_1"
            cy="328.9042"
            cx="297.20134"
            stroke={`url(#${instancePrefix}_svg_30)`}
            fill="none"
          />
          <ellipse
            strokeWidth="10"
            ry="60"
            rx="60"
            id="svg_3"
            cy="155.57079"
            cx="91.0474"
            stroke={`url(#${instancePrefix}_svg_30)`}
            fill="none"
          />
          <line
            transform="rotate(180 208.359 259.892)"
            stroke={`url(#${instancePrefix}_svg_30)`}
            id="svg_13"
            y2="240.50686"
            x2="185.37385"
            y1="279.27619"
            x1="231.3432"
            strokeWidth="10"
            fill="none"
          />
          <line
            transform="rotate(180 155.189 213.123)"
            stroke={`url(#${instancePrefix}_svg_30)`}
            id="svg_15"
            y2="195.17398"
            x2="134.93282"
            y1="231.07143"
            x1="175.44567"
            strokeWidth="10"
            fill="none"
          />
          <ellipse
            transform="rotate(180 373.346 71.4441)"
            strokeWidth="10"
            ry="40"
            rx="40"
            id="svg_18"
            cy="71.44416"
            cx="373.34564"
            stroke={`url(#${instancePrefix}_svg_34)`}
            fill="none"
          />
          <line
            transform="rotate(128.209 174.601 129.299)"
            stroke={`url(#${instancePrefix}_svg_31)`}
            id="svg_19"
            y2="113.41624"
            x2="152.76208"
            y1="145.18206"
            x1="196.43996"
            strokeWidth="10"
            fill="none"
          />
          <line
            transform="rotate(128.209 306.366 92.2403)"
            stroke={`url(#${instancePrefix}_svg_33)`}
            id="svg_23"
            y2="76.35741"
            x2="284.52683"
            y1="108.12323"
            x1="328.2047"
            strokeWidth="10"
            fill="none"
          />
          <line
            transform="rotate(128.209 239.307 111.064)"
            stroke={`url(#${instancePrefix}_svg_32)`}
            id="svg_25"
            y2="95.18094"
            x2="217.46799"
            y1="126.94676"
            x1="261.14586"
            strokeWidth="10"
            fill="none"
          />
        </g>
      </svg>
    </Box>
  );
}

export function LogoBisflowRed({ sx }: BoxProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/icons/bisflow-logo-red.svg"
      sx={{ width: 40, height: 40, ...sx }}
    />
  );
}

export function LogoBisflowGreen({ sx }: BoxProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/icons/bisflow-logo-green.svg"
      sx={{ width: 40, height: 40, ...sx }}
    />
  );
}

export function LogoBisflowBlue({ sx }: BoxProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/icons/bisflow-logo-blue.svg"
      sx={{ width: 40, height: 40, ...sx }}
    />
  );
}
