import {
  getFirestore,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  collection,
  Timestamp, doc,
} from 'firebase/firestore';
// Paths

export const fetchDepartments = (onSuccess: (data: Record<string, any>[]) => void) => {
  const departmentsDbReference = collection(getFirestore(), 'entities');

  const queryData = query(
    departmentsDbReference,
    // Checking company based documents
    where('company_uid', '==', 'pf0ArUxSiZNwqXZnun6rVZSkrRH2'),
    // Checking status based documents - processing/template/archive/removed
    where('active', '==', true),
    where('entity_type', '==', 'department'),
    // Ordering by created date
    orderBy('display_name', 'asc'),
  );

  getDocs(queryData).then((departmentsSnapshots) => {
    const departments: Record<string, any>[] = [];
    departmentsSnapshots.forEach((departmentSnapshot) => {
      const departmentData = departmentSnapshot.data();
      if (departmentData) {
        departments.push({
          uid: departmentData.uid,
          display_name: departmentData.display_name,
          manager_uid: departmentData.manager_uid,
          members_count: departmentData.members ?? 0,
          status: departmentData.status ?? 'active',
        });
      }
    });
    onSuccess(departments);
  }).catch((error) => console.log(error?.message))
}

export const fetchAllEmployees = (onSuccess: (data: Record<string, any>[]) => void) => {
  const departmentsDbReference = collection(getFirestore(), 'employees');
  const queryData = query(
    departmentsDbReference,
    // Checking company based documents
    where('company_uid', '==', 'pf0ArUxSiZNwqXZnun6rVZSkrRH2'),
    // Checking status based documents - processing/template/archive/removed
    where('active', '==', true),
  );

  getDocs(queryData).then((departmentsSnapshots) => {
    const departments: Record<string, any>[] = [];
    departmentsSnapshots.forEach((departmentSnapshot) => {
      const departmentData = departmentSnapshot.data();
      if (departmentData) {
        departments.push(departmentData);
      }
    });
    onSuccess(departments);
  }).catch((error) => console.log(error?.message))
}
