/**
 * @description - The routing path variables
 */

/**
 * @description - The method is concat paths and return final path as string.
 * @example - Above example returns string of final path '/app/dashboard'
 * path('/app', '/dashboard')
 * @param root - Root link
 * @param subLink - SubLink
 * @return string - Final path after concatenation
 */
function path(root: string, subLink: string) {
  // Checking if the root path is just a '/', in that case the final path should
  // be only the sub-link
  return root !== '/' ? `${root}/${subLink}` : subLink;
}

// ================================================================================================================== //
// ====================================================== COMMON ==================================================== //
// ================================================================================================================== //

// Landing page
export const PATH_FULL_LANDING = 'https://bisflow.io';
// Admin application path
export const PATH_FULL_ADMIN = 'https://admin.bisflow.io';
// Requests
export const PATH_FULL_REQUESTS = 'https://requests.bisflow.io';
// Document management application
export const PATH_FULL_DOCS = 'https://docs.bisflow.io';
// Contracts management application
export const PATH_FULL_CONTRACT = 'https://contract.bisflow.io';
// Procurement application to manage buying staff
export const PATH_FULL_PROCUREMENT = 'https://procurement.bisflow.io';
// Request management application full path
export const PATH_FULL_REQUEST_MANAGEMENT = 'https://requests.bisflow.io';
// HR management application to manage HR workflow
export const PATH_FULL_HR = 'https://hr.bisflow.io';
// Payva - Expense management application
export const PATH_FULL_PAYVA = 'https://payva.bisflow.io';
// Home - The path to the home
export const PATH_ROOTS_HOME = '/';
// ================================================================================================================== //
// ======================================================= COMMON =================================================== //
// ================================================================================================================== //

/**
 * SUB-LINK COMMON SUB PATHS
 */
const PATH_DASHBOARD = 'dashboard';
const PATH_SETTINGS = 'settings';
const PATH_PROCESSES = 'processes';
const PATH_TEAM = 'team';
const PATH_PERSONNEL = 'personnel';
const PATH_DEPARTMENTS = 'departments';
const PATH_GROUPS = 'groups';
const PATH_BUDGETS = 'budgets';
const PATH_PAYMENTS = 'payments';
const PATH_INVOICES = 'invoices';
const PATH_FLOWS = 'flows';
const PATH_FORMS = 'forms';
const PATH_STOCKS = 'stocks';
const PATH_GOODS = 'goods';
const PATH_MANAGE = 'manage';
const PATH_DOCUMENTS = 'documents';
const PATH_CONTRACTS = 'contracts';
const PATH_ACTS = 'acts';
const PATH_AUTHORIZATIONS = 'authorizations';
const PATH_ARCHIVE = 'archive';
const PATH_ACTIVE = 'active';
const PATH_PROCUREMENT = 'procurement';
const PATH_FINANCIAL = 'financial';
const PATH_INVENTORY = 'inventory';
const PATH_VENDORS = 'vendors';
const PATH_REQUESTS = 'requests';
const PATH_REPORTS = 'reports';
const PATH_ANALYTICS = 'analytics';
const PATH_EDIT = 'edit';
const PATH_VIEW = 'view';
const PATH_CREATE = 'create';
const PATH_LIST = 'list';

// ================================================================================================================== //
// ======================================================== AUTH ==================================================== //
// ================================================================================================================== //

/**
 * SUB-LINK AUTHENTICATION SUB PATHS
 */
const PATH_AUTH = 'auth';
const PATH_ROOTS_AUTH = `${PATH_ROOTS_HOME}${PATH_AUTH}`;
const PATH_LOGIN = 'login-password';
const PATH_LOGIN_EMAIL = 'login-email';
const PATH_LOGIN_UNPROTECTED = 'login-password-unprotected';
const PATH_REGISTER = 'register';
const PATH_REGISTER_UNPROTECTED = 'register-unprotected';
const PATH_RESET_PASSWORD = 'reset-password';
const PATH_VERIFY_PASSWORD = 'verify-password';

/**
 * @description - Contains authentication related all paths
 */
export const PATH_APP_AUTH = {
  rootFull: PATH_ROOTS_AUTH,
  root: PATH_AUTH,
  loginFull: path(PATH_ROOTS_AUTH, PATH_LOGIN),
  login: PATH_LOGIN,
  loginEmailFull: path(PATH_ROOTS_AUTH, PATH_LOGIN_EMAIL),
  loginEmail: PATH_LOGIN_EMAIL,
  loginUnprotectedFull: path(PATH_ROOTS_AUTH, PATH_LOGIN_UNPROTECTED),
  loginUnprotected: PATH_LOGIN_UNPROTECTED,
  registerFull: path(PATH_ROOTS_AUTH, PATH_REGISTER),
  register: PATH_REGISTER,
  registerUnprotectedFull: path(PATH_ROOTS_AUTH, PATH_REGISTER_UNPROTECTED),
  registerUnprotected: PATH_REGISTER_UNPROTECTED,
  resetPasswordFull: path(PATH_ROOTS_AUTH, PATH_RESET_PASSWORD),
  resetPassword: PATH_RESET_PASSWORD,
  verifyFull: path(PATH_ROOTS_AUTH, PATH_VERIFY_PASSWORD),
  verify: PATH_VERIFY_PASSWORD
};

// ================================================================================================================== //
// ===================================================== PLATFORM =================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_APP = 'app';
const PATH_ROOTS_APP = `${PATH_ROOTS_HOME}${PATH_APP}`;

/**
 * @description - Contains platform based paths
 */
export const PATH_APP_PLATFORM = {
  rootFull: PATH_ROOTS_APP,
  root: PATH_APP,
}

// ================================================================================================================== //
// ===================================================== ACCOUNT ==================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_ACCOUNT = 'account';
const PATH_ROOTS_ACCOUNT = `${PATH_ROOTS_HOME}${PATH_ACCOUNT}`;
const PATH_PROFILE = 'profile';

/**
 * @description - Contains platform based paths
 */
export const PATH_APP_ACCOUNT = {
  rootFull: PATH_ROOTS_ACCOUNT,
  root: PATH_ACCOUNT,
  profileFull: path(PATH_ROOTS_ACCOUNT, PATH_PROFILE),
  profile: PATH_PROFILE,
  settingsFull: path(PATH_ROOTS_ACCOUNT, PATH_SETTINGS),
  settings: PATH_SETTINGS
}

// ================================================================================================================== //
// ======================================================= HELP ===================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_HELP = 'help';
const PATH_ROOTS_HELP = `${PATH_ROOTS_HOME}${PATH_HELP}`;
const PATH_INSTRUCTION = 'instructions';

/**
 * @description - Contains platform based paths
 */
export const PATH_APP_HELP = {
  rootFull: PATH_ROOTS_HELP,
  root: PATH_HELP,
  instructionsFull: path(PATH_ROOTS_HELP, PATH_INSTRUCTION),
  profile: PATH_INSTRUCTION
}

// ================================================================================================================== //
// ======================================================== MAIN ==================================================== //
// ================================================================================================================== //
/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_APPS = 'apps';
const PATH_ROOTS_APPS = `${PATH_ROOTS_HOME}${PATH_APPS}`;

/**
 * @description - Contains main/home related all paths
 */
export const PATH_APP_MAIN = {
  root: PATH_ROOTS_HOME,
  rootFull: PATH_ROOTS_HOME,
  apps: PATH_APPS,
  appsFull: PATH_ROOTS_APPS,
}
// ================================================================================================================== //
// ===================================================== REQUESTS =================================================== //
// ================================================================================================================== //
/**
 * SUB_LINKS - REQUESTS SUB PATHS
 */

/**
 * @description - Contains main/home related all paths
 */
export const PATH_APP_REQUESTS = {
  root: PATH_APP,
  rootFull: PATH_ROOTS_APP,
  create: PATH_CREATE,
  createFull: path(PATH_ROOTS_APP, PATH_CREATE),
}
// ================================================================================================================== //
// ======================================================= ADMIN ==================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */


/**
 * @description - Contains administrative related all paths
 */
export const PATH_APP_ADMIN = {
  rootFull: PATH_ROOTS_APP,
  root: PATH_APP,
  dashboardFull: path(PATH_ROOTS_APP, PATH_DASHBOARD),
  dashboard: PATH_DASHBOARD,
  processes: {
    root: PATH_PROCESSES,
    rootFull: path(PATH_ROOTS_APP, PATH_PROCESSES),
    flowsFull: path(PATH_ROOTS_APP, path(PATH_PROCESSES, PATH_FLOWS)),
    flows: path(PATH_PROCESSES, PATH_FLOWS),
    formsFull: path(PATH_ROOTS_APP, path(PATH_PROCESSES, PATH_FORMS)),
    forms: path(PATH_PROCESSES, PATH_FORMS),
  },
  personnel: {
    rootFull: path(PATH_ROOTS_APP, PATH_PERSONNEL),
    root: PATH_PERSONNEL,
    teamFull: path(PATH_ROOTS_APP, path(PATH_PERSONNEL, PATH_TEAM)),
    team: path(PATH_PERSONNEL, PATH_TEAM),
    departmentsFull: path(PATH_ROOTS_APP, path(PATH_PERSONNEL, PATH_DEPARTMENTS)),
    departments: path(PATH_PERSONNEL, PATH_DEPARTMENTS),
    groupsFull: path(PATH_ROOTS_APP, path(PATH_PERSONNEL, PATH_GROUPS)),
    groups: path(PATH_PERSONNEL, PATH_GROUPS),
  },
  finance: {
    rootFull: path(PATH_ROOTS_APP, PATH_FINANCIAL),
    root: PATH_FINANCIAL,
    budgetsFull: path(PATH_ROOTS_APP, path(PATH_FINANCIAL, PATH_BUDGETS)),
    budgets: path(PATH_FINANCIAL, PATH_BUDGETS),
    paymentsFull: path(PATH_ROOTS_APP, path(PATH_FINANCIAL, PATH_PAYMENTS)),
    payments: path(PATH_FINANCIAL, PATH_PAYMENTS),
    invoicesFull: path(PATH_ROOTS_APP, path(PATH_FINANCIAL, PATH_INVOICES)),
    invoices: path(PATH_FINANCIAL, PATH_INVOICES),
  },
  inventory: {
    rootFull: path(PATH_ROOTS_APP, PATH_INVENTORY),
    root: PATH_INVENTORY,
    stocksFull: path(PATH_ROOTS_APP, path(PATH_INVENTORY, PATH_STOCKS)),
    stocks: path(PATH_INVENTORY, PATH_STOCKS),
    goodsFull: path(PATH_ROOTS_APP, path(PATH_INVENTORY, PATH_GOODS)),
    goods: path(PATH_INVENTORY, PATH_GOODS),
  },
  vendors: {
    rootFull: path(PATH_ROOTS_APP, PATH_VENDORS),
    root: PATH_VENDORS,
    manageFull: path(PATH_ROOTS_APP, path(PATH_VENDORS, PATH_MANAGE)),
    manage: path(PATH_VENDORS, PATH_MANAGE),
  },
  requests: {
    rootFull: path(PATH_ROOTS_APP, PATH_REQUESTS),
    root: PATH_REQUESTS,
    goodsFull: path(PATH_ROOTS_APP, path(PATH_REQUESTS, PATH_GOODS)),
    goods: path(PATH_REQUESTS, PATH_GOODS),
  },
  documents: {
    rootFull: path(PATH_ROOTS_APP, PATH_DOCUMENTS),
    root: PATH_DOCUMENTS,
    archiveFull: path(PATH_ROOTS_APP, path(PATH_DOCUMENTS, PATH_ARCHIVE)),
    archive: path(PATH_DOCUMENTS, PATH_ARCHIVE),
    authorizationsFull: path(PATH_ROOTS_APP, path(PATH_DOCUMENTS, PATH_AUTHORIZATIONS)),
    authorizations: path(PATH_DOCUMENTS, PATH_AUTHORIZATIONS),
    activeFull: path(PATH_ROOTS_APP, path(PATH_DOCUMENTS, PATH_ACTIVE)),
    active: path(PATH_DOCUMENTS, PATH_ACTIVE),
  },
  reports: {
    rootFull: path(PATH_ROOTS_APP, PATH_REPORTS),
    root: PATH_REPORTS,
    procurementFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_PROCUREMENT)),
    procurement: path(PATH_REPORTS, PATH_PROCUREMENT),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_DOCUMENTS)),
    documents: path(PATH_REPORTS, PATH_DOCUMENTS),
    financialFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_FINANCIAL)),
    financial: path(PATH_REPORTS, PATH_FINANCIAL),
  },
  analytics: {
    rootFull: path(PATH_ROOTS_APP, PATH_ANALYTICS),
    root: PATH_ANALYTICS,
    procurementFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_PROCUREMENT)),
    procurement: path(PATH_ANALYTICS, PATH_PROCUREMENT),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_DOCUMENTS)),
    documents: path(PATH_ANALYTICS, PATH_DOCUMENTS),
    financialFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_FINANCIAL)),
    financial: path(PATH_ANALYTICS, PATH_FINANCIAL),
  },
}

// ================================================================================================================== //
// ======================================================== DOCS ==================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - DOCUMENTATION SUB PATHS
 */

const PATH_ITEM_ID = ':id'

/**
 * @description - Contains documentation related all paths
 */
export const PATH_APP_DOCS = {
  rootFull: PATH_ROOTS_APP,
  root: PATH_APP,
  dashboardFull: path(PATH_ROOTS_APP, PATH_DASHBOARD),
  dashboard: PATH_DASHBOARD,
  active: {
    root: PATH_ACTIVE,
    rootFull: path(PATH_ROOTS_APP, PATH_ACTIVE),
    contractsFull: path(PATH_ROOTS_APP, path(PATH_ACTIVE, PATH_CONTRACTS)),
    contracts: path(PATH_ACTIVE, PATH_CONTRACTS),
    contractFull: path(PATH_ROOTS_APP, path(path(PATH_ACTIVE, PATH_CONTRACTS), PATH_ITEM_ID)),
    contract: path(path(PATH_ACTIVE, PATH_CONTRACTS), PATH_ITEM_ID),
    contractCreateFull: path(PATH_ROOTS_APP, path(path(PATH_ACTIVE, PATH_CONTRACTS), PATH_CREATE)),
    contractCreate: path(path(PATH_ACTIVE, PATH_CONTRACTS), PATH_CREATE),
    contractEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_ACTIVE, PATH_CONTRACTS), PATH_ITEM_ID), PATH_EDIT)),
    contractEdit: path(path(path(PATH_ACTIVE, PATH_CONTRACTS), PATH_ITEM_ID), PATH_EDIT),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_ACTIVE, PATH_DOCUMENTS)),
    documents: path(PATH_ACTIVE, PATH_DOCUMENTS),
    documentFull: path(PATH_ROOTS_APP, path(path(PATH_ACTIVE, PATH_DOCUMENTS), PATH_ITEM_ID)),
    document: path(path(PATH_ACTIVE, PATH_DOCUMENTS), PATH_ITEM_ID),
    documentCreateFull: path(PATH_ROOTS_APP, path(path(PATH_ACTIVE, PATH_DOCUMENTS), PATH_CREATE)),
    documentCreate: path(path(PATH_ACTIVE, PATH_DOCUMENTS), PATH_CREATE),
    documentEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_ACTIVE, PATH_DOCUMENTS), PATH_ITEM_ID), PATH_EDIT)),
    documentEdit: path(path(path(PATH_ACTIVE, PATH_DOCUMENTS), PATH_ITEM_ID), PATH_EDIT),
    actsFull: path(PATH_ROOTS_APP, path(PATH_ACTIVE, PATH_ACTS)),
    acts: path(PATH_ACTIVE, PATH_ACTS),
    actFull: path(path(PATH_ACTIVE, PATH_ACTS), PATH_ITEM_ID),
    act: path(PATH_ROOTS_APP, path(path(PATH_ACTIVE, PATH_ACTS), PATH_ITEM_ID)),
    actCreateFull: path(PATH_ROOTS_APP, path(path(PATH_ACTIVE, PATH_ACTS), PATH_CREATE)),
    actCreate: path(path(PATH_ACTIVE, PATH_ACTS), PATH_CREATE),
    actEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_ACTIVE, PATH_ACTS), PATH_ITEM_ID), PATH_EDIT)),
    actEdit: path(path(path(PATH_ACTIVE, PATH_ACTS), PATH_ITEM_ID), PATH_EDIT),
  },
  archive: {
    root: PATH_ARCHIVE,
    rootFull: path(PATH_ROOTS_APP, PATH_ARCHIVE),
    contractsFull: path(PATH_ROOTS_APP, path(PATH_ARCHIVE, PATH_CONTRACTS)),
    contracts: path(PATH_ARCHIVE, PATH_CONTRACTS),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_ARCHIVE, PATH_DOCUMENTS)),
    documents: path(PATH_ARCHIVE, PATH_DOCUMENTS),
    actsFull: path(PATH_ROOTS_APP, path(PATH_ARCHIVE, PATH_ACTS)),
    acts: path(PATH_ARCHIVE, PATH_ACTS),
  },
  reports: {
    rootFull: path(PATH_ROOTS_APP, PATH_REPORTS),
    root: PATH_REPORTS,
    contractsFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_CONTRACTS)),
    contracts: path(PATH_REPORTS, PATH_CONTRACTS),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_DOCUMENTS)),
    documents: path(PATH_REPORTS, PATH_DOCUMENTS),
    actsFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_ACTS)),
    acts: path(PATH_REPORTS, PATH_ACTS),
  },
  analytics: {
    rootFull: path(PATH_ROOTS_APP, PATH_ANALYTICS),
    root: PATH_ANALYTICS,
    contractsFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_CONTRACTS)),
    contracts: path(PATH_ANALYTICS, PATH_CONTRACTS),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_DOCUMENTS)),
    documents: path(PATH_ANALYTICS, PATH_DOCUMENTS),
    actsFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_ACTS)),
    acts: path(PATH_ANALYTICS, PATH_ACTS),
  },
}
