/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { ModelContract } from 'src/@types';
import { DocModel } from "src/pages/docs/models";
// API services
import { saveDocument, uploadFile, uploadFiles, fetchAllDocumentsFromProject } from "src/services/documents/documents";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The slice state for the contract(s) page
 */
type ContractState = {
  isLoadingContracts: boolean;
  contractFetchingError: Error | string | null;
  contracts: ModelContract[] | null;
  contract: ModelContract | string | null;
}

/**
 * @description - The initial state of the contract slice
 */
const contractsSliceInitialState: ContractState = {
  isLoadingContracts: false,
  contractFetchingError: null,
  contracts: null,
  contract: null,
}

const contractsSlice = createSlice({
  name: 'contracts',
  initialState: contractsSliceInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingContracts = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoadingContracts = false;
      state.contractFetchingError = action.payload;
    },
    // GET CONTRACTS
    getContractsSuccess(state, action) {
      state.isLoadingContracts = false;
      state.contractFetchingError = null;
      state.contracts = action.payload;
      state.contract = null;
    },
    // GET CONTACT
    getContractSuccess(state, action) {
      state.isLoadingContracts = false;
      state.contractFetchingError = null;
      state.contracts = null;
      state.contract = action.payload;
    },
    createContractSuccess(state, action) {
      state.isLoadingContracts = false;
      state.contractFetchingError = null;
      state.contracts = null;
      state.contract = action.payload;
    }
  }
});

// The reducers/actions
export default contractsSlice.reducer;
export const {} = contractsSlice.actions;

// ================================================= SLICES METHODS ================================================= //

/**
 * @description - The method is fetching contracts list by project uid
 * @param user_uid
 * @param company_uid
 */
export function getContractsList(
  user_uid: string,
  company_uid: string,
  filter?: string | null,
) {
  return () => {
    dispatch(contractsSlice.actions.startLoading());
    try {
      fetchAllDocumentsFromProject(
        'contract',
        'new',
        'o5JBSEJQ7QFh1uovZQ8C',
        company_uid,
        user_uid,
        (allContracts) => dispatch(contractsSlice.actions.getContractsSuccess(allContracts)),
        (error) => dispatch(contractsSlice.actions.hasError(error)),
        filter
      );
    } catch (error) {
      console.log(error)
      dispatch(contractsSlice.actions.hasError(error))
    }
  }
}

export function getArchiveContractsList(
  user_uid: string,
  company_uid: string,
  filter?: string | null,
) {
  return () => {
    dispatch(contractsSlice.actions.startLoading());
    try {
      fetchAllDocumentsFromProject(
        'contract',
        'archive',
        'o5JBSEJQ7QFh1uovZQ8C',
        company_uid,
        user_uid,
        (allContracts) => dispatch(contractsSlice.actions.getContractsSuccess(allContracts)),
        (error) => dispatch(contractsSlice.actions.hasError(error)),
        filter
      );
    } catch (error) {
      dispatch(contractsSlice.actions.hasError(error))
    }
  }
}
/**
 * @description - The method is executing the contract creation process.
 * @param company_uid
 * @param project_uid
 * @param template_uid
 * @param attachment
 * @param display_name
 * @param file
 * @param from
 * @param to
 * @param user_uid
 */
export function createContractByAttachment(
  company_uid: string,
  project_uid: string,
  template_uid: string | null,
  attachment: string | null,
  display_name: string,
  file: File,
  from: Record<string, any>,
  to: Record<string, any>,
  user_uid?: string,
  documents_type?: string | null,
  documents?: File,
  supplements?: File,
) {
  return () => {
    dispatch(contractsSlice.actions.startLoading());
    try {
      saveDocument(
        {
          company_uid,
          project_uid,
          template_uid,
          attachment,
          display_name,
          from,
          to,
          user_uid,
          ...(documents ? {documents: documents.name, documents_type: documents_type || null} : {}),
          ...(supplements ? {supplements: supplements.name} : {}),
        },
        'contract',
        (uid) => {
          uploadFiles(
            uid,
            company_uid,
            'contract',
            [file, documents || null, supplements || null],
            (result) => {
              dispatch(contractsSlice.actions.createContractSuccess(result))
            },
            (error) => dispatch(contractsSlice.actions.hasError(error))
          );
        },
        (error) => dispatch(contractsSlice.actions.hasError(error))
      );
    } catch (error) {
      dispatch(contractsSlice.actions.hasError(error))
    }
  }
}


