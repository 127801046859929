// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

export default function BlockContent({ customText }: { customText?: string }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {customText ?? 'Ներբեռնել թվային փաստաթուղթ'}
        </Typography>

        {/*<Typography variant="body2" sx={{ color: 'text.secondary' }}>*/}
        {/*  /!*Գցեք ֆայլերը այստեղ կամ սեղմեք&nbsp;*!/*/}
        {/*  <Typography*/}
        {/*    variant="body2"*/}
        {/*    component="span"*/}
        {/*    sx={{ color: 'primary.main', textDecoration: 'underline' }}*/}
        {/*  >*/}
        {/*    ընտրել փաստաթուղթը*/}
        {/*  </Typography>*/}
        {/*  /!*&nbsp;ձեր մեքենաից*!/*/}
        {/*</Typography>*/}
      </Box>
    </Stack>
  );
}
